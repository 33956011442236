import {
  Button,
  Checkbox,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import { Box, styled } from "@mui/system";
import React from "react";
import { LoadingPage } from "../../sdk";
import useRoomFilter from "../hooks/useRoomFilter";
import { LoadingButton } from "@mui/lab";

const RoomFilter = () => {
  const {
    hotelId,
    formEdit,
    filterData,
    isLoading,
    selectedFilter,
    handleRedirect,
    handleRoomChange,
    saveFilterData
  } = useRoomFilter();

  return isLoading.getApi ? (
    <LoadingPage />
  ) : (
    <RoomConfigPageStack>
      <Stack direction={"column"} justifyContent={"space-between"} gap={2}>
        <Typography fontSize="18px" fontWeight="500" mb={1}>
          Room Type Filter Mapping
        </Typography>
        {filterData?.map((item) => (
          <Stack
            key={item?.rateMetricHotelCode}
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            gap={4}
            fullWidth
          >
            <Typography width="40%">{item?.hotelName}</Typography>
            <FormControl sx={{ width: "40%" }}>
              <InputLabel id="demo-simple-select-label">Standard</InputLabel>
              <Select
                sx={{ paddingRight: "32px" }}
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                multiple
                value={
                  selectedFilter?.[item?.rateMetricHotelCode]?.standard?.map(({roomDetailsId})=>roomDetailsId) || []
                }
                label="Standard"
                renderValue={(selected) => {
                  const arr = item?.roomDetails
                    ?.filter((val) => (selected?.includes(val.roomDetailsId)))
                    ?.map((item) => item.roomName);
                  return arr.join(", ");
                }}
                onChange={(e) => handleRoomChange(e.target.value, item?.rateMetricHotelCode, "standard")}
              >
                {item?.roomDetails?.map((room, idx) => {
                  if (selectedFilter?.[item?.rateMetricHotelCode]?.club?.some(({roomDetailsId})=>(roomDetailsId === room.roomDetailsId)) ||
                      selectedFilter?.[item?.rateMetricHotelCode]?.suite?.some(({roomDetailsId})=>(roomDetailsId === room.roomDetailsId))
                  ) {
                    return null
                   }
                  return (
                    <MenuItem key={idx} value={room?.roomDetailsId}>
                      <Typography
                        width="300px"
                        whiteSpace="nowrap"
                        overflow="hidden"
                        textOverflow="ellipsis"
                      >
                        {room?.roomName}
                      </Typography>
                      <Checkbox checked={selectedFilter?.[item?.rateMetricHotelCode]?.standard?.some(({roomDetailsId})=>(roomDetailsId === room.roomDetailsId))} />
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            <FormControl sx={{ width: "40%" }}>
              <InputLabel id="demo-simple-select-label">Club</InputLabel>
              <Select
                sx={{ paddingRight: "32px" }}
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                multiple
                value={
                  selectedFilter?.[item?.rateMetricHotelCode]?.club?.map(({roomDetailsId})=>roomDetailsId) || []
                }
                label="Club"
                renderValue={(selected) => {
                  const arr = item?.roomDetails
                    ?.filter((val) => (selected?.includes(val.roomDetailsId)))
                    ?.map((item) => item.roomName);
                  return arr.join(", ");
                }}
                onChange={(e) => handleRoomChange(e.target.value, item?.rateMetricHotelCode, "club")}
              >
                {item?.roomDetails?.map((room, idx) => {
                  if (selectedFilter?.[item?.rateMetricHotelCode]?.standard?.some(({roomDetailsId})=>(roomDetailsId === room.roomDetailsId)) ||
                      selectedFilter?.[item?.rateMetricHotelCode]?.suite?.some(({roomDetailsId})=>(roomDetailsId === room.roomDetailsId))
                  ) {
                    return null
                   }
                  return (
                    <MenuItem key={idx} value={room?.roomDetailsId}>
                      <Typography
                        width="300px"
                        whiteSpace="nowrap"
                        overflow="hidden"
                        textOverflow="ellipsis"
                      >
                        {room?.roomName}
                      </Typography>
                      <Checkbox checked={selectedFilter?.[item?.rateMetricHotelCode]?.club?.some(({roomDetailsId})=>(roomDetailsId === room.roomDetailsId))} />
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            <FormControl sx={{ width: "40%" }}>
              <InputLabel id="demo-simple-select-label">Suite</InputLabel>
              <Select
                sx={{ paddingRight: "32px" }}
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                multiple
                value={
                  selectedFilter?.[item?.rateMetricHotelCode]?.suite?.map(({roomDetailsId})=>roomDetailsId) || []
                }
                label="Suite"
                renderValue={(selected) => {
                  const arr = item?.roomDetails
                    ?.filter((val) => (selected?.includes(val.roomDetailsId)))
                    ?.map((item) => item.roomName);
                  return arr.join(", ");
                }}
                onChange={(e) => handleRoomChange(e.target.value, item?.rateMetricHotelCode, "suite")}
              >
                {item?.roomDetails?.map((room, idx) => {
                  if (selectedFilter?.[item?.rateMetricHotelCode]?.standard?.some(({roomDetailsId})=>(roomDetailsId === room.roomDetailsId)) ||
                      selectedFilter?.[item?.rateMetricHotelCode]?.club?.some(({roomDetailsId})=>(roomDetailsId === room.roomDetailsId))
                  ) {
                    return null
                   }
                  return (
                    <MenuItem key={idx} value={room?.roomDetailsId}>
                      <Typography
                        width="300px"
                        whiteSpace="nowrap"
                        overflow="hidden"
                        textOverflow="ellipsis"
                      >
                        {room?.roomName}
                      </Typography>
                      <Checkbox checked={selectedFilter?.[item?.rateMetricHotelCode]?.suite?.some(({roomDetailsId})=>(roomDetailsId === room.roomDetailsId))} />
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Stack>
        ))}
        <Box display="flex" justifyContent="flex-end" gap={4}>
          <LoadingButton
            variant="contained"
            onClick={() => saveFilterData({ clear: false })}
            loading={isLoading.saveApi}
            disabled={!formEdit}
          >
            save
          </LoadingButton>
          <Button
            variant="contained"
            onClick={() => handleRedirect(`/hotel/${hotelId}/setup/competitors`)}
          >
            Next
          </Button>
        </Box>
      </Stack>
    </RoomConfigPageStack>
  );
};

const RoomConfigPageStack = styled(Stack)(() => ({
  width: " 100%",
  background: "#ffffff",
  border: "none",
  padding: "20px",
  outline: "none",
  height: "calc(100vh - 160px)",
  overflowY: "scroll",
  overflowX: "hidden",
  boxShadow: "1px 4px 10px rgba(48, 111, 188, 0.2)",
  gap: "16px",
  ".borderStack": {
    gap: "16px",
    padding: "24px",
    boxShadow: "1px 4px 10px rgba(48, 111, 188, 0.2)",
  },
  ".disableMapping": {
    width: "160px",
    backgroundColor: "#a90f0f",
    "&:hover": {
      backgroundColor: "#c30909",
    },
  },
}));

export default RoomFilter;
