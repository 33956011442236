import {
  Box as BX,
  Divider,
  InputLabel,
  Select as SL,
  Table as TB,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { CloseRounded, Schedule } from "@mui/icons-material";
import { styled } from "@mui/system";
import { Card, GhostButton as GB, PrimaryButton } from "../sdk";
export const Select = styled(SL)`
  .MuiSelect-root {
    width: 56px;
    font: bold 16px/20px Roboto;
    padding-right: 88px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin: 0px;
    outline: none;
    height: 100%;
  }

  .MuiSelect-selectMenu {
    font: normal normal bold 16px/20px Roboto;
    letter-spacing: 0px;
  }

  .MuiInput-input:focus {
    background-color: transparent !important;
  }
`;
export const H = styled(Typography)`
  font: normal normal bold 24px/30px Roboto;
  letter-spacing: 0px;
  color: #ffffff;
  position: fixed;
  top: 13px;
  left: 80px;
  color: white;
  z-index: 999;
`;
export const Wrapper = styled(BX)`
  flex: 1;
`;
export const CompetitorPage = styled(BX)`
  padding: 80px 20px 20px 80px;
  position: absolute;
  width: 100%;
  height: 100%;
`;
export const Header = styled(BX)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 99%;
  height: 52px;
  background: #ffffff 0% 0% no-repeat padding-box;
  font: normal normal bold 16px/20px Roboto;
  letter-spacing: 0px;
  color: #281e53;
  margin: auto;
`;
export const HeaderCardLeft = styled(BX)`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex: 2;
  margin-left: 100px;
`;
export const HeaderCardRight = styled(BX)`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
`;
export const HeaderCardCenter = styled(BX)`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex: 2;
  button.MuiButtonBase-root {
    opacity: 1;
    span.MuiTab-wrapper {
      font: normal normal bold 16px / 20px Roboto;
      color: #281e53;
    }
  }
`;
export const Hr = styled(Divider)`
  color: #f5f4f8;
  width: 1px;
  height: 30px;
`;
export const DateLabel = styled(Typography)`
  font: normal normal normal 16px/20px Roboto;
  letter-spacing: 0px;
  color: #281e53;
  padding-right: 22px;
`;
export const UploadBtn = styled(GB)`
  text-align: right;
  font: Bold 16px/20px Roboto;
  letter-spacing: 0px;
  color: #343434;
  display: flex;
  align-items: flex-end;
`;
export const NetworkPrompt = styled(BX)`
  margin-left: 100px;
`;
export const ScheduleIconDisabled = styled(Schedule)`
  &:hover {
    cursor: not-allowed;
  }
`;

export const Section = styled(BX)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  padding: 8px 42px 8px 36px;
  height: 200px;
`;
export const ChooseBtn = styled(InputLabel)`
  font-family: Roboto;
  font-size: 12px;
  font-weight: bold;
  line-height: 1.25;
  color: #000000;
  &:hover {
    cursor: pointer;
  }
  height: 24px;
  border: 1px solid #130453;
  border-radius: 5px;
  color: #130453;
  padding: 4px 10px;
`;
export const Filename = styled(Typography)`
  font: normal normal normal 12px/15px Roboto;
  margin-left: 10px;
`;
export const Title = styled(BX)`
  width: 100%;
  text-align: center;
  font: normal normal normal 20px/25px Roboto;
  letter-spacing: 0px;
  color: #333333;
`;

export const Head = styled(BX)`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;
export const UploadSection = styled(BX)`
  padding: 22px;
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
`;

export const Done = styled(PrimaryButton)`
  width: 80px;
  height: 24px;
  background: #130453 0% 0% no-repeat padding-box;
  border: 1px solid #130453;
  border-radius: 5px;
  font: normal normal bold 12px/15px Roboto;
  letter-spacing: 0px;
  color: #ffffff;
`;

export const SectionWrapper = styled(BX)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  width: 380px;
  height: ${(props) =>
    props.compPriceUploadForSpecificDate ? "250px" : "170px"};
`;
export const Label = styled(InputLabel)`
  font: normal normal bold 16px/20px Roboto;
  color: #130453;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-right: 50px;
`;
export const DateSection = styled(BX)`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  padding-left: 20px;
  padding-top: 24px;
`;
export const UploadFilename = styled(Typography)`
  font: normal normal normal 12px/15px Roboto;
  margin-left: 10px;
  margin-top: 10px;
  overflow: hidden;
  width: 100px;
  height: 30px;
  text-align: center;
`;
export const Cross = styled(CloseRounded)`
  width: 16px;
  height: 16px;
  :hover {
    cursor: pointer;
  }
`;

export const UploadSectionModel = styled(BX)`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
`;
export const RatepingSection = styled(BX)``;
export const ExpediaSection = styled(BX)``;
export const OTASection = styled(BX)``;

export const UploadFile = styled(BX)`
  display: flex;
  width: 100%;
  justify-content: center;
  margin-top: 24px;
  align-items: center;
`;

export const UploadHeader = styled(BX)`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
`;

export const CrossSmall = styled(CloseRounded)`
  width: 16px;
  height: 16px;
  margin-top: -80px;
  margin-left: 10px;
  :hover {
    cursor: pointer;
  }
`;

export const UploadDone = styled(PrimaryButton)`
  width: 80px;
  height: 24px;
  background: #130453 0% 0% no-repeat padding-box;
  border: 1px solid #130453;
  border-radius: 5px;
  font: normal normal bold 12px/15px Roboto;
  letter-spacing: 0px;
  color: #ffffff;
`;

export const Table = styled(TB)`
  margin: auto;
  font-family: Roboto;
  font-size: 16px;
  line-height: 1.25;
`;

export const Th = styled(TableCell)`
  &.MuiTableCell-head {
    vertical-align: bottom;
    font: normal normal bold 16px/20px Roboto;
    color: #130453;
    background-color: white;
    border: none;
    text-align: center;
    padding: 10px 0px;
  }
`;
export const Td = styled(TableCell)`
  &.MuiTableCell-body {
    font: normal normal normal 16px/20px Roboto;
    color: #333333;
    border: none;
    height: 32px;
    padding: 0 15px;
    text-align: center;
  }
`;

export const Tr = styled(TableRow)(({ theme }) => ({
  ".root": {},
}));

export const Thead = styled(TableHead)``;
export const Tbody = styled(TableBody)``;

export const MyCard = styled(Card)`
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: auto;
  flex-wrap: nowrap;
  margin: 0px;
  margin-top: 16px;
  padding: 20px;
  padding-top: 4px;
  height: calc(100vh - 160px);
`;

export const DATE = styled(BX)`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 5px;
`;
export const DAY = styled(BX)`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 30px;
`;

export const Box = styled(BX)`
  &.MuiBox-root {
    padding: 10px;
  }
`;
