import React, { useEffect, useMemo, useState } from "react";
import {
  HeaderCard,
  LabelNew,
  MainContainer,
  P,
  StyledCalenderWrapper,
  SubText,
} from "./styles";

import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormGroup,
  IconButton,
  InputLabel,
  ListItemText,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Slider,
  Snackbar,
  SnackbarContent,
  Stack,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  Typography,
  styled,
} from "@mui/material";
import {
  CalendarToday,
  Map,
  ReportProblem,
  SaveAlt,
  ViewList,
} from "@mui/icons-material";
import UseEventDetails from "./useEventDetails";
import { useAuth } from "../sdk";
import DatePicker from "react-modern-calendar-datepicker";
import EventDetailTable from "./EventDetailTable";
import MapView from "./MapView";

const iOSBoxShadow =
  "0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.13),0 0 0 1px rgba(0,0,0,0.02)";

const IOSSlider = styled(Slider)(({ theme }) => ({
  color: "#007bff",
  height: 5,
  padding: "15px 0",
  "& .MuiSlider-thumb": {
    height: 20,
    width: 20,
    backgroundColor: "#fff",
    boxShadow: "0 0 2px 0px rgba(0, 0, 0, 0.1)",
    "&:focus, &:hover, &.Mui-active": {
      boxShadow: "0px 0px 3px 1px rgba(0, 0, 0, 0.1)",
      "@media (hover: none)": {
        boxShadow: iOSBoxShadow,
      },
    },
    "&:before": {
      boxShadow:
        "0px 0px 1px 0px rgba(0,0,0,0.2), 0px 0px 0px 0px rgba(0,0,0,0.14), 0px 0px 1px 0px rgba(0,0,0,0.12)",
    },
  },
  "& .MuiSlider-valueLabel": {
    fontSize: 12,
    fontWeight: "normal",
    top: -4,
    backgroundColor: "unset",
    color: theme.palette.text.primary,
    "&::before": {
      display: "none",
    },
    "& *": {
      background: "transparent",
      color: "#000",
      ...theme.applyStyles("dark", {
        color: "#fff",
      }),
    },
  },
  "& .MuiSlider-track": {
    border: "none",
    height: 5,
  },
  "& .MuiSlider-rail": {
    opacity: 0.5,
    boxShadow: "inset 0px 0px 4px -2px #000",
    backgroundColor: "#d0d0d0",
  },
  ...theme.applyStyles("dark", {
    color: "#0a84ff",
  }),
}));
const OptionsType = [
  { label: "Generic", value: "generic" },
  { label: "City based", value: "city_based" },
];
export const CalenderIcon = styled(CalendarToday)`
  position: absolute;
  top: "50%";
  width: 20px;
  height: 22px;
  color: #306fbc;
  z-index: 98;
  margin-left: -32px;
  margin-top: 4px;
  cursor: pointer;
`;
const marks = [
  {
    value: 0,
    label: "0 km",
  },
  {
    value: 100,
    label: "100 km",
  },
];

export default function EventDetails({ setPageHeader }) {
  const { currentHotel } = useAuth();

  useEffect(() => {
    setPageHeader("All Events");
    return () => {
      setPageHeader("");
    };
  }, [setPageHeader]);

  const {
    eventTypes,
    selectedType,
    setSelectedType,
    radius,
    setRadius,
    view,
    setView,
    fromDateNew,
    toDateNew,
    setFromDateNew,
    settoDateNew,
    eventDetails,
    error,
    count,
    page,
    handleChangePage,
    handleChangeRowsPerPage,
    rowsPerPage,
    selectedRows,
    setSelectedRows,
    handleAdd,
    networkMsg,
    setnetworkMsg,
    searchInput,
    setSearchInput,
    searchKeyword,
    setSearchKeyword,
    options,
    selectedKeywords,
    setSelectedKeywords,
    setPage,
    downloadFile,
    loading,
    type,
    setType,
  } = UseEventDetails();
  const { permission } = useAuth();
  let addUpcomingEvents = null;

  for (let key in permission) {
    if (permission.hasOwnProperty(key)) {
      if (permission[key].name === "addUpcomingEvents") {
        addUpcomingEvents = permission[key];
      }
    }
  }

  const handleType = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedType(typeof value === "string" ? value.split(",") : value);
    setPage(0);
  };

  const googleMapMarker = useMemo(() => {
    return {
      eventDetails: eventDetails?.event_details?.events?.map((val) => ({
        id: val.id,
        name: val.eventname,
        position: {
          lat: Number(val.latitude),
          lng: Number(val.longitude),
        },
        venue: val.venue,
        address: val.address,
        date: val.startdate,
        color: val.color,
      })),
      competitorDetails: eventDetails?.competitors_details?.map((val) => ({
        id: val.id,
        name: val.name,
        position: {
          lat: Number(val.latitude),
          lng: Number(val.longitude),
        },
      })),
    };
  }, [eventDetails]);

  const formatInputValueToDate = () => {
    if (toDateNew) {
      const value =
        `${toDateNew.day < 10 ? "0" + toDateNew.day : toDateNew.day}` +
        "/" +
        `${toDateNew.month < 10 ? "0" + toDateNew.month : toDateNew.month}` +
        "/" +
        `${toDateNew.year}`;
      return value;
    }
  };
  const formatInputValue = () => {
    if (fromDateNew) {
      const value =
        `${fromDateNew.day < 10 ? "0" + fromDateNew.day : fromDateNew.day}` +
        "/" +
        `${
          fromDateNew.month < 10 ? "0" + fromDateNew.month : fromDateNew.month
        }` +
        "/" +
        `${fromDateNew.year}`;
      return value;
    }
  };
  const handleChangeNew = (nextView) => {
    setView(nextView);
  };
  const renderCustomInput = ({ ref }) => (
    <input
      readOnly
      ref={ref}
      value={formatInputValue()}
      style={{
        textAlign: "left",
        marginBottom: "2px",
        fontSize: "14px",

        borderRadius: "10px",

        color: "#000000",
        outline: "none",
        height: "30px",
        border: "none",
        font: "bold 16px / 20px Roboto",
        cursor: "pointer",
        width: "140px",
        paddingLeft: "14px",
      }}
      className="my-custom-input-class"
    />
  );
  const renderCustomInputToDate = ({ ref }) => (
    <input
      readOnly
      ref={ref}
      value={formatInputValueToDate()}
      style={{
        textAlign: "left",
        marginBottom: "2px",
        fontSize: "14px",

        borderRadius: "10px",

        color: "#000000",
        outline: "none",
        height: "30px",
        border: "none",
        font: "bold 16px / 20px Roboto",
        cursor: "pointer",
        width: "140px",
        paddingLeft: "14px",
      }}
      className="my-custom-input-class"
    />
  );
  const handleEventChange = (eventId) => {
    setSelectedType((prev) => {
      const isSelected = prev?.some((row) => row === eventId);
      if (isSelected) {
        return prev.filter((row) => row !== eventId);
      } else {
        return [...prev, eventId];
      }
    });
    setPage(0);
  };

  return (
    <MainContainer direction={"row"}>
      <Stack className="leftPanel">
        <Stack className="leftContent">
          <Typography mt={2} className="hotelName">
            {currentHotel?.name}
          </Typography>
          <Typography mt={1} className="address">
            {currentHotel?.address}
          </Typography>
          <Stack mt={3} gap={2}>
            <Stack gap={1} className="dateContainer">
              <P>Date</P>
              <Stack display="flex" alignItems="center" gap={1}>
                <HeaderCard>
                  <LabelNew>From</LabelNew>
                  {/* <label> */}
                    <StyledCalenderWrapper>
                      <DatePicker
                        value={fromDateNew}
                        onChange={(date) => {
                          setFromDateNew(date);
                          setPage(0);
                        }}
                        renderInput={renderCustomInput}
                        calendarClassName="myCustomCalendar"
                        calendarPopperPosition="bottom"
                      />
                      <CalenderIcon />
                    </StyledCalenderWrapper>
                  {/* </label> */}
                </HeaderCard>
                <HeaderCard>
                  <LabelNew>To</LabelNew>
                  {/* <label> */}
                    <StyledCalenderWrapper
                      sx={{
                        marginLeft: "15px",
                      }}
                    >
                      <DatePicker
                        value={toDateNew}
                        onChange={settoDateNew}
                        renderInput={renderCustomInputToDate}
                        calendarClassName="myCustomCalendar"
                        calendarPopperPosition="bottom"
                        minimumDate={fromDateNew}
                      />
                      <CalenderIcon />
                    </StyledCalenderWrapper>
                  {/* </label> */}
                </HeaderCard>
              </Stack>
            </Stack>
            <Stack gap={2} className="dateContainer">
              <P>Event Types</P>
              <Box
                width={"100%"}
                mt={1}
                display={"flex"}
                justifyContent={"center"}
              >
                <FormControl
                  variant="outlined"
                  sx={{
                    width: "300px",
                    borderRadius: "8px", // Add border radius to FormControl
                    "& .MuiOutlinedInput-root": {
                      borderRadius: "8px", // Add border radius to the Select input
                    },
                  }}
                  size="small"
                >
                  <InputLabel id="event-category-label">
                    Event Category
                  </InputLabel>
                  <Select
                    multiple
                    value={selectedType}
                    onChange={handleType}
                    disableUnderline
                    label="Event Category"
                    classes={{
                      paper: "dropdownStyle",
                    }}
                    renderValue={(selected) =>
                      selected
                        .map(
                          (id) =>
                            eventTypes.find((type) => type?.id === id)?.name
                        )
                        .join(", ")
                    }
                    MenuProps={{
                      PaperProps: {
                        style: {
                          width: "300px",
                          maxHeight: "220px",
                          overflowY: "auto",
                        },
                      },
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left",
                      },
                      transformOrigin: {
                        vertical: "top",
                        horizontal: "left",
                      },
                      getContentAnchorEl: null,
                    }}
                    style={{ color: "#110641" }}
                  >
                    {eventTypes.map((name) => (
                      <MenuItem key={name?.id} value={name?.id}>
                        <ListItemText
                          classes={{
                            primary: "dropdownStyle",
                          }}
                          sx={{
                            whiteSpace: "normal",
                            // wordBreak: "break-word",
                            // textWrap: "wrap",
                          }}
                          primary={name?.name}
                        />
                        <Checkbox
                          checked={selectedType.includes(name?.id)}
                          style={{
                            color: "rgb(48,111,188)",
                          }}
                        />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
            </Stack>
            <Stack gap={2} className="dateContainer">
              <Stack gap={2}>
                <P>Radius</P>
                <SubText>0Km - 100Km</SubText>
              </Stack>
              <Box display="flex" position={"relative"} justifyContent="center">
                <IOSSlider
                  aria-label="ios slider"
                  value={radius}
                  valueLabelFormat={(value) => `${value} km`}
                  valueLabelDisplay="on"
                  onChange={(e, val) => {
                    setRadius(val);
                    setPage(0);
                  }}
                  sx={{
                    width: "80%",
                  }}
                />
                <Typography
                  sx={{
                    position: "absolute",
                    top: 20,
                    left: 0,
                    fontSize: "12px",
                    color: "#544f4f",
                  }}
                >
                  0 km
                </Typography>
                <Typography
                  sx={{
                    position: "absolute",
                    top: 20,
                    right: 0,
                    fontSize: "12px",
                    color: "#544f4f",
                  }}
                >
                  100 km
                </Typography>
                {/* <Slider
                  value={radius}
                  marks={marks}
                  valueLabelDisplay="auto"
                  onChange={(e, val) => {
                    setRadius(val);
                    setPage(0);
                  }}
                  sx={{
                    width: "80%",
                  }}
                /> */}
              </Box>
            </Stack>
          </Stack>
        </Stack>
      </Stack>

      <Box className="viewContent">
        {error ? (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            width="100%"
            height={"100vh"}
          >
            <Typography
              display={"flex"}
              alignItems={"center"}
              textTransform={
                error === "failed to check hotel location url"
                  ? "none"
                  : "capitalize"
              }
            >
              <ReportProblem color="error" />{" "}
              <Typography ml={1}>
                {error === "failed to check hotel location url"
                  ? "Hotel Location URL is not available"
                  : error}
              </Typography>
            </Typography>
          </Box>
        ) : (
          <>
            <Box
              display="flex"
              gap={2}
              justifyContent="space-between"
              pt={3}
              pb={2}
            >
              <Stack direction={"row"} gap={"10px"}>
                <TextField
                  required
                  select
                  label="Keyword Type"
                  size="small"
                  value={type}
                  onChange={(e) => {
                    setType(e?.target?.value ?? "");
                    setSelectedKeywords([]);
                  }}
                  sx={{
                    width: "180px",
                    "& .MuiInputBase-root": {
                      borderRadius: "45px",
                    },
                  }}
                >
                  {OptionsType.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
                <Autocomplete
                  multiple
                  size="small"
                  limitTags={2} // Limit the number of visible tags
                  options={options ?? []}
                  getOptionLabel={(option) => option.keywordName}
                  defaultValue={[]}
                  value={selectedKeywords}
                  onChange={(e, value) => {
                    setSelectedKeywords(value);
                    setPage(0);
                  }}
                  onInputChange={(e, newInputValue) =>
                    setSearchKeyword(newInputValue)
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label="Keywords"
                      size="small"
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: null,
                      }}
                      sx={{
                        label: {
                          background: "white",
                        },
                        ".MuiAutocomplete-inputRoot": {
                          border: "1px solid rgba(0, 0, 0, 0.23)",
                          width: 200,
                          overflowX: "auto",
                          borderRadius: "45px",
                          flexWrap: "nowrap",
                          "&::-webkit-scrollbar": {
                            display: "none",
                          },
                          " -ms-overflow-style": "none",
                          " scrollbar-width": "none",
                          input: {
                            minWidth: 100,
                          },
                          fieldset: {
                            display: "none",
                          },
                        },
                        "& .MuiAutocomplete-inputRoot.Mui-focused": {
                          border: "2px solid #1976d2",
                        },
                      }}
                    />
                  )}
                />
                <TextField
                  label="Search"
                  variant="outlined"
                  size="small"
                  value={searchInput}
                  onChange={(e) => {
                    setSearchInput(e?.target?.value ?? "");
                    setPage(0);
                  }}
                  sx={{
                    maxWidth: "220px",
                    "& .MuiInputBase-root": {
                      borderRadius: "45px",
                    },
                  }}
                />
              </Stack>

              <Stack direction="row" gap={2}>
                <IconButton onClick={downloadFile} className="downloadBtn">
                  <SaveAlt />
                </IconButton>
                <ToggleButtonGroup
                  value={view}
                  exclusive
                  color="primary"
                  style={{
                    height: "32px",
                    boxShadow: "0px 4px 4px rgba(3, 4, 94, 0.2)",
                    borderRadius: "8px",
                  }}
                >
                  <ToggleButton
                    value="map"
                    aria-label="map"
                    onClick={() => handleChangeNew("map")}
                  >
                    <Map style={{ color: "#163a90" }} />
                  </ToggleButton>
                  <ToggleButton
                    value="table"
                    aria-label="table"
                    onClick={() => handleChangeNew("table")}
                  >
                    <ViewList style={{ color: "#163a90" }} />
                  </ToggleButton>
                </ToggleButtonGroup>
              </Stack>
            </Box>

            {view === "table" ? (
              <>
                <Stack
                  direction={"row"}
                  justifyContent="end"
                  alignItems="center"
                >
                  {addUpcomingEvents && (
                    <Button
                      disabled={selectedRows?.length === 0}
                      onClick={handleAdd}
                      className="eventButton"
                    >
                      Add to Event Calendar
                    </Button>
                  )}
                </Stack>
                {loading ? (
                  <Box className="loadingBox">
                    <CircularProgress />
                  </Box>
                ) : (
                  <EventDetailTable
                    eventDetails={eventDetails}
                    count={count}
                    page={page}
                    handleChangePage={handleChangePage}
                    handleChangeRowsPerPage={handleChangeRowsPerPage}
                    rowsPerPage={rowsPerPage}
                    selectedRows={selectedRows}
                    setSelectedRows={setSelectedRows}
                    permission={addUpcomingEvents}
                  />
                )}
              </>
            ) : loading ? (
              <Box className="loadingBox">
                <CircularProgress />
              </Box>
            ) : (
              <MapView
                markers={googleMapMarker}
                radius={radius}
                mapContainerStyle={{
                  width: "100%",
                  height: "74vh",
                }}
                currentHotel={currentHotel}
                center={{
                  lat: Number(eventDetails?.hotel_details?.latitude),
                  lng: Number(eventDetails?.hotel_details?.longitude),
                }}
              />
            )}
          </>
        )}
      </Box>

      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        open={networkMsg}
        autoHideDuration={3000}
        onClose={() => setnetworkMsg(false)}
      >
        {networkMsg && (
          <SnackbarContent
            style={{
              backgroundColor:
                networkMsg === "Event Added Successfully"
                  ? "#228b22"
                  : "#CA3433",
            }}
            message={networkMsg}
          />
        )}
      </Snackbar>
    </MainContainer>
  );
}
