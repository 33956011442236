const currencies = {
AED: {	
cc:	"AED",
symbol:	"د.إ;",
name:	"UAE dirham"
},
AFN: {	
cc:	"AFN",
symbol:	"Afs",
name:	"Afghan afghani"
},
ALL: {	
cc:	"ALL",
symbol:	"L",
name:	"Albanian lek"
},
AMD: {	
cc:	"AMD",
symbol:	"AMD",
name:	"Armenian dram"
},
ANG: {	
cc:	"ANG",
symbol:	"NAƒ",
name:	"Netherlands Antillean gulden"
},
AOA: {	
cc:	"AOA",
symbol:	"Kz",
name:	"Angolan kwanza"
},
ARS: {	
cc:	"ARS",
symbol:	"$",
name:	"Argentine peso"
},
AUD: {	
cc:	"AUD",
symbol:	"$",
name:	"Australian dollar"
},
AWG: {	
cc:	"AWG",
symbol:	"ƒ",
name:	"Aruban florin"
},
AZN: {	
cc:	"AZN",
symbol:	"AZN",
name:	"Azerbaijani manat"
},
BAM: {	
cc:	"BAM",
symbol:	"KM",
name:	"Bosnia and Herzegovina konvertibilna marka"
},
BBD: {	
cc:	"BBD",
symbol:	"Bds$",
name:	"Barbadian dollar"
},
BDT: {	
cc:	"BDT",
symbol:	"৳",
name:	"Bangladeshi taka"
},
BGN: {	
cc:	"BGN",
symbol:	"BGN",
name:	"Bulgarian lev"
},
BHD: {	
cc:	"BHD",
symbol:	".د.ب",
name:	"Bahraini dinar"
},
BIF: {	
cc:	"BIF",
symbol:	"FBu",
name:	"Burundi franc"
},
BMD: {	
cc:	"BMD",
symbol:	"BD$",
name:	"Bermudian dollar"
},
BND: {	
cc:	"BND",
symbol:	"B$",
name:	"Brunei dollar"
},
BOB: {	
cc:	"BOB",
symbol:	"Bs.",
name:	"Bolivian boliviano"
},
BRL: {	
cc:	"BRL",
symbol:	"R$",
name:	"Brazilian real"
},
BSD: {	
cc:	"BSD",
symbol:	"B$",
name:	"Bahamian dollar"
},
BTN: {	
cc:	"BTN",
symbol:	"Nu.",
name:	"Bhutanese ngultrum"
},
BWP: {	
cc:	"BWP",
symbol:	"P",
name:	"Botswana pula"
},
BYR: {	
cc:	"BYR",
symbol:	"Br",
name:	"Belarusian ruble"
},
BZD: {	
cc:	"BZD",
symbol:	"BZ$",
name:	"Belize dollar"
},
CAD: {	
cc:	"CAD",
symbol:	"$",
name:	"Canadian dollar"
},
CDF: {	
cc:	"CDF",
symbol:	"F",
name:	"Congolese franc"
},
CHF: {	
cc:	"CHF",
symbol:	"Fr.",
name:	"Swiss franc"
},
CLP: {	
cc:	"CLP",
symbol:	"$",
name:	"Chilean peso"
},
CNY: {	
cc:	"CNY",
symbol:	"¥",
name:	"Chinese/Yuan renminbi"
},
COP: {	
cc:	"COP",
symbol:	"Col$",
name:	"Colombian peso"
},
CRC: {	
cc:	"CRC",
symbol:	"₡",
name:	"Costa Rican colon"
},
CUC: {	
cc:	"CUC",
symbol:	"$",
name:	"Cuban peso"
},
CVE: {	
cc:	"CVE",
symbol:	"Esc",
name:	"Cape Verdean escudo"
},
CZK: {	
cc:	"CZK",
symbol:	"Kč",
name:	"Czech koruna"
},
DJF: {	
cc:	"DJF",
symbol:	"Fdj",
name:	"Djiboutian franc"
},
DKK: {	
cc:	"DKK",
symbol:	"Kr",
name:	"Danish krone"
},
DOP: {	
cc:	"DOP",
symbol:	"RD$",
name:	"Dominican peso"
},
DZD: {	
cc:	"DZD",
symbol:	"د.ج",
name:	"Algerian dinar"
},
EEK: {	
cc:	"EEK",
symbol:	"KR",
name:	"Estonian kroon"
},
EGP: {	
cc:	"EGP",
symbol:	"£",
name:	"Egyptian pound"
},
ERN: {	
cc:	"ERN",
symbol:	"Nfa",
name:	"Eritrean nakfa"
},
ETB: {	
cc:	"ETB",
symbol:	"Br",
name:	"Ethiopian birr"
},
EUR: {	
cc:	"EUR",
symbol:	"€",
name:	"European Euro"
},
FJD: {	
cc:	"FJD",
symbol:	"FJ$",
name:	"Fijian dollar"
},
FKP: {	
cc:	"FKP",
symbol:	"£",
name:	"Falkland Islands pound"
},
GBP: {	
cc:	"GBP",
symbol:	"£",
name:	"British pound"
},
GEL: {	
cc:	"GEL",
symbol:	"GEL",
name:	"Georgian lari"
},
GHS: {	
cc:	"GHS",
symbol:	"GH₵",
name:	"Ghanaian cedi"
},
GIP: {	
cc:	"GIP",
symbol:	"£",
name:	"Gibraltar pound"
},
GMD: {	
cc:	"GMD",
symbol:	"D",
name:	"Gambian dalasi"
},
GNF: {	
cc:	"GNF",
symbol:	"FG",
name:	"Guinean franc"
},
GQE: {	
cc:	"GQE",
symbol:	"CFA",
name:	"Central African CFA franc"
},
GTQ: {	
cc:	"GTQ",
symbol:	"Q",
name:	"Guatemalan quetzal"
},
GYD: {	
cc:	"GYD",
symbol:	"GY$",
name:	"Guyanese dollar"
},
HKD: {	
cc:	"HKD",
symbol:	"HK$",
name:	"Hong Kong dollar"
},
HNL: {	
cc:	"HNL",
symbol:	"L",
name:	"Honduran lempira"
},
HRK: {	
cc:	"HRK",
symbol:	"kn",
name:	"Croatian kuna"
},
HTG: {	
cc:	"HTG",
symbol:	"G",
name:	"Haitian gourde"
},
HUF: {	
cc:	"HUF",
symbol:	"Ft",
name:	"Hungarian forint"
},
IDR: {	
cc:	"IDR",
symbol:	"Rp",
name:	"Indonesian rupiah"
},
ILS: {	
cc:	"ILS",
symbol:	"₪",
name:	"Israeli new sheqel"
},
INR: {	
cc:	"INR",
symbol:	"₹",
name:	"Indian rupee"
},
IQD: {	
cc:	"IQD",
symbol:	"د.ع",
name:	"Iraqi dinar"
},
IRR: {	
cc:	"IRR",
symbol:	"IRR",
name:	"Iranian rial"
},
ISK: {	
cc:	"ISK",
symbol:	"kr",
name:	"Icelandic króna"
},
JMD: {	
cc:	"JMD",
symbol:	"J$",
name:	"Jamaican dollar"
},
JOD: {	
cc:	"JOD",
symbol:	"JOD",
name:	"Jordanian dinar"
},
JPY: {	
cc:	"JPY",
symbol:	"¥",
name:	"Japanese yen"
},
KES: {	
cc:	"KES",
symbol:	"KSh",
name:	"Kenyan shilling"
},
KGS: {	
cc:	"KGS",
symbol:	"сом",
name:	"Kyrgyzstani som"
},
KHR: {	
cc:	"KHR",
symbol:	"៛",
name:	"Cambodian riel"
},
KMF: {	
cc:	"KMF",
symbol:	"KMF",
name:	"Comorian franc"
},
KPW: {	
cc:	"KPW",
symbol:	"W",
name:	"North Korean won"
},
KRW: {	
cc:	"KRW",
symbol:	"W",
name:	"South Korean won"
},
KWD: {	
cc:	"KWD",
symbol:	"KWD",
name:	"Kuwaiti dinar"
},
KYD: {	
cc:	"KYD",
symbol:	"KY$",
name:	"Cayman Islands dollar"
},
KZT: {	
cc:	"KZT",
symbol:	"T",
name:	"Kazakhstani tenge"
},
LAK: {	
cc:	"LAK",
symbol:	"KN",
name:	"Lao kip"
},
LBP: {	
cc:	"LBP",
symbol:	"£",
name:	"Lebanese lira"
},
LKR: {	
cc:	"LKR",
symbol:	"Rs",
name:	"Sri Lankan rupee"
},
LRD: {	
cc:	"LRD",
symbol:	"L$",
name:	"Liberian dollar"
},
LSL: {	
cc:	"LSL",
symbol:	"M",
name:	"Lesotho loti"
},
LTL: {	
cc:	"LTL",
symbol:	"Lt",
name:	"Lithuanian litas"
},
LVL: {	
cc:	"LVL",
symbol:	"Ls",
name:	"Latvian lats"
},
LYD: {	
cc:	"LYD",
symbol:	"LD",
name:	"Libyan dinar"
},
MAD: {	
cc:	"MAD",
symbol:	"MAD",
name:	"Moroccan dirham"
},
MDL: {
cc:	"MDL",
symbol:	"MDL",
name:	"Moldovan leu"
},
MGA: {	
cc:	"MGA",
symbol:	"FMG",
name:	"Malagasy ariary"
},
MKD: {	
cc:	"MKD",
symbol:	"MKD",
name:	"Macedonian denar"
},
MMK: {	
cc:	"MMK",
symbol:	"K",
name:	"Myanma kyat"
},
MNT: {	
cc:	"MNT",
symbol:	"₮",
name:	"Mongolian tugrik"
},
MOP: {	
cc:	"MOP",
symbol:	"P",
name:	"Macanese pataca"
},
MRO: {	
cc:	"MRO",
symbol:	"UM",
name:	"Mauritanian ouguiya"
},
MUR: {	
cc:	"MUR",
symbol:	"Rs",
name:	"Mauritian rupee"
},
MVR: {	
cc:	"MVR",
symbol:	"Rf",
name:	"Maldivian rufiyaa"
},
MWK: {	
cc:	"MWK",
symbol:	"MK",
name:	"Malawian kwacha"
},
MXN: {	
cc:	"MXN",
symbol:	"$",
name:	"Mexican peso"
},
MYR: {	
cc:	"MYR",
symbol:	"RM",
name:	"Malaysian ringgit"
},
MZM: {	
cc:	"MZM",
symbol:	"MTn",
name:	"Mozambican metical"
},
NAD: {	
cc:	"NAD",
symbol:	"N$",
name:	"Namibian dollar"
},
NGN: {	
cc:	"NGN",
symbol:	"₦",
name:	"Nigerian naira"
},
NIO: {	
cc:	"NIO",
symbol:	"C$",
name:	"Nicaraguan córdoba"
},
NOK: {	
cc:	"NOK",
symbol:	"kr",
name:	"Norwegian krone"
},
NPR: {	
cc:	"NPR",
symbol:	"NRs",
name:	"Nepalese rupee"
},
NZD: {	
cc:	"NZD",
symbol:	"NZ$",
name:	"New Zealand dollar"
},
OMR: {	
cc:	"OMR",
symbol:	"OMR",
name:	"Omani rial"
},
PAB: {	
cc:	"PAB",
symbol:	"B./",
name:	"Panamanian balboa"
},
PEN: {	
cc:	"PEN",
symbol:	"S/.",
name:	"Peruvian nuevo sol"
},
PGK: {	
cc:	"PGK",
symbol:	"K",
name:	"Papua New Guinean kina"
},
PHP: {	
cc:	"PHP",
symbol:	"₱",
name:	"Philippine peso"
},
PKR: {	
cc:	"PKR",
symbol:	"Rs.",
name:	"Pakistani rupee"
},
PLN: {	
cc:	"PLN",
symbol:	"zł",
name:	"Polish zloty"
},
PYG: {	
cc:	"PYG",
symbol:	"₲",
name:	"Paraguayan guarani"
},
QAR: {	
cc:	"QAR",
symbol:	"QR",
name:	"Qatari riyal"
},
RON: {	
cc:	"RON",
symbol:	"L",
name:	"Romanian leu"
},
RSD: {	
cc:	"RSD",
symbol:	"din.",
name:	"Serbian dinar"
},
RUB: {	
cc:	"RUB",
symbol:	"R",
name:	"Russian ruble"
},
SAR: {	
cc:	"SAR",
symbol:	"SR",
name:	"Saudi riyal"
},
SBD: {	
cc:	"SBD",
symbol:	"SI$",
name:	"Solomon Islands dollar"
},
SCR: {	
cc:	"SCR",
symbol:	"SR",
name:	"Seychellois rupee"
},
SDG: {	
cc:	"SDG",
symbol:	"SDG",
name:	"Sudanese pound"
},
SEK: {	
cc:	"SEK",
symbol:	"kr",
name:	"Swedish krona"
},
SGD: {	
cc:	"SGD",
symbol:	"S$",
name:	"Singapore dollar"
},
SHP: {	
cc:	"SHP",
symbol:	"£",
name:	"Saint Helena pound"
},
SLL: {	
cc:	"SLL",
symbol:	"Le",
name:	"Sierra Leonean leone"
},
SOS: {	
cc:	"SOS",
symbol:	"Sh.",
name:	"Somali shilling"
},
SRD: {	
cc:	"SRD",
symbol:	"$",
name:	"Surinamese dollar"
},
SYP: {	
cc:	"SYP",
symbol:	"LS",
name:	"Syrian pound"
},
SZL: {	
cc:	"SZL",
symbol:	"E",
name:	"Swazi lilangeni"
},
THB: {	
cc:	"THB",
symbol:	"฿",
name:	"Thai baht"
},
TJS: {	
cc:	"TJS",
symbol:	"TJS",
name:	"Tajikistani somoni"
},
TMT: {	
cc:	"TMT",
symbol:	"m",
name:	"Turkmen manat"
},
TND: {	
cc:	"TND",
symbol:	"DT",
name:	"Tunisian dinar"
},
TRY: {	
cc:	"TRY",
symbol:	"TRY",
name:	"Turkish new lira"
},
TTD: {	
cc:	"TTD",
symbol:	"TT$",
name:	"Trinidad and Tobago dollar"
},
TWD: {	
cc:	"TWD",
symbol:	"NT$",
name:	"New Taiwan dollar"
},
TZS: {	
cc:	"TZS",
symbol:	"TZS",
name:	"Tanzanian shilling"
},
UAH: {	
cc:	"UAH",
symbol:	"UAH",
name:	"Ukrainian hryvnia"
},
UGX: {	
cc:	"UGX",
symbol:	"USh",
name:	"Ugandan shilling"
},
USD: {	
cc:	"USD",
symbol:	"US$",
name:	"United States dollar"
},
UYU: {	
cc:	"UYU",
symbol:	"$U",
name:	"Uruguayan peso"
},
UZS: {	
cc:	"UZS",
symbol:	"UZS",
name:	"Uzbekistani som"
},
VEB: {	
cc:	"VEB",
symbol:	"Bs",
name:	"Venezuelan bolivar"
},
VND: {	
cc:	"VND",
symbol:	"₫",
name:	"Vietnamese dong"
},
VUV: {	
cc:	"VUV",
symbol:	"VT",
name:	"Vanuatu vatu"
},
WST: {	
cc:	"WST",
symbol:	"WS$",
name:	"Samoan tala"
},
XAF: {	
cc:	"XAF",
symbol:	"CFA",
name:	"Central African CFA franc"
},
XCD: {	
cc:	"XCD",
symbol:	"EC$",
name:	"East Caribbean dollar"
},
XDR: {	
cc:	"XDR",
symbol:	"SDR",
name:	"Special Drawing Rights"
},
XOF: {	
cc:	"XOF",
symbol:	"CFA",
name:	"West African CFA franc"
},
XPF: {	
cc:	"XPF",
symbol:	"F",
name:	"CFP franc"
},
YER: {	
cc:	"YER",
symbol:	"YER",
name:	"Yemeni rial"
},
ZAR: {	
cc:	"ZAR",
symbol:	"R",
name:	"South African rand"
},
ZMK: {	
cc:	"ZMK",
symbol:	"ZK",
name:	"Zambian kwacha"
},
ZWR: {	
cc:	"ZWR",
symbol:	"Z$",
name:	"Zimbabwean dollar"
},
}

export {currencies}