import React, { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useAuth } from "../../sdk";

const EMPTY_ROW = {
  advanceMin: "",
  advanceMax: "",
  occMax: "",
  occMin: "",
  demandLevel: "",
  index: 0,
};

export function useManualDLCheck(
  copyFromHotelId,
  midweek,
  isMidweek,
  openSelectbar,
  dlMeedweekValue,
  dlWeekendValue,
  isChecked
) {
  const { hotelId } = useParams();
  const { token, authFetch } = useAuth();
  const [details, setDetails] = useState([]);
  const [isInEditMode, setIsInEditMode] = useState(false);
  const [isCancelled, setIsCancelled] = useState(false);
  const [checkMidweekValidation, setCheckMidweekValidation] = useState(false);
  const [checkWeekendValidation, setCheckWeekendValidation] = useState(false);
  const [isSaved, setIsSaved] = useState(false);
  const [loading, setLoading] = useState(false);
  const [Error, setError] = useState(false);
  const [open, setOpen] = useState();
  const [networkMsg, setnetworkMsg] = useState();
  const dayType = isMidweek ? "midweek" : "weekend";
  const [tableConfig, setTableConfig] = React.useState({});
  const [days, setDaysCount] = useState(0);
  const [weekendDays, setWeekendDays] = useState(0);
  const [check, setCheck] = useState(false);
  const [idNew, setId] = useState("");

  useEffect(() => {
    const tConfig = [];
    details.forEach((detail) => {
      tConfig[detail.index] = tConfig[detail.index] || {
        rowSpan: 0,
      };
      tConfig[detail.index].rowSpan++;
    });
    setTableConfig(tConfig);
  }, [details]);

  useEffect(() => {
    if (!token) return;
    refreshDL();
    getDLCall();
  }, [token, hotelId]);

  useEffect(() => {
    if (isInEditMode) {
      const allValidEntries = details.every(isValid);
      if (dayType === "midweek") {
        if (allValidEntries) setCheckMidweekValidation(false);
        else setCheckMidweekValidation(true);
      }
      if (dayType === "weekend") {
        if (allValidEntries) setCheckWeekendValidation(false);
        else setCheckWeekendValidation(true);
      }

      if (allValidEntries)
        if (!details[0]?.advanceMax && !details[0]?.occMax) {
          setDetails([
            {
              ...EMPTY_ROW,
            },
          ]);
        }
    } else if (isSaved) {
      updateDL();
    } else if (isCancelled) {
      refreshDL();
    }
  }, [isInEditMode, details]);

  useEffect(() => {
    (async () => {
      if (copyFromHotelId === null && !midweek) return;
      if (!openSelectbar) refreshDL(copyFromHotelId);
    })();
  }, [midweek]);

  useEffect(() => {
    (async () => {
      if (dlWeekendValue <= 0 || dlWeekendValue === "") return;
      postDLCall(copyFromHotelId,dlWeekendValue, false);
    })();
  }, [dlWeekendValue]);

  useEffect(() => {
    (async () => {
      if (dlMeedweekValue <= 0 || dlMeedweekValue === "") return;
      postDLCall(copyFromHotelId, dlMeedweekValue,true);
    })();
  }, [dlMeedweekValue]);
  useEffect(() => {
    (async () => {
      if (weekendDays > 0 || dlWeekendValue > 0) {
        postDLCall(copyFromHotelId,dlWeekendValue, false);
      } else if (Number.isInteger(dlMeedweekValue) === false) {
        postDLCallNew(copyFromHotelId);
      }
      if (days > 0 || dlMeedweekValue > 0) {
        postDLCall(copyFromHotelId, dlMeedweekValue,true);
      } else if (Number.isInteger(dlMeedweekValue) === false) {
        postDLCallNew(copyFromHotelId);
      }
    })();
  }, [isChecked]);

  const updateDL = useCallback(async () => {
    setLoading(true);
    setIsSaved(false);
    const outputArray = [];
    let index = -1;
    const validArray = details.filter(isValid);
    setDetails(validArray);
    if (validArray) {
      validArray.forEach((detail) => {
        if (detail.advanceMin || detail.advanceMin === 0) {
          outputArray.push({
            advanceMin: detail.advanceMin,
            advanceMax: detail.advanceMax,
            occupancyRange: [
              {
                occMin: detail.occMin,
                occMax: detail.occMax,
                demandLevel: detail.demandLevel,
              },
            ],
            isMidWeek: isMidweek,
          });
          index++;
        } else {
          outputArray[index] = {
            ...outputArray[index],
            occupancyRange: [
              ...outputArray[index].occupancyRange,
              {
                occMin: detail.occMin,
                occMax: detail.occMax,
                demandLevel: detail.demandLevel,
              },
            ],
          };
        }
      });
    }

    const { post } = await authFetch({
      path: `/hotel/${hotelId}/manual-dl-check/${dayType}/all`,
    });
    const { response, error, data } = await post(outputArray);
    if (!response?.ok) {
      setnetworkMsg(
        data?.messageToUser || isMidweek
          ? "Can't Update Midweek DL Check Setup"
          : "Can't Update Weekend DL Check Setup"
      );
      setOpen(true);
      console.log(error);
    }
    refreshDL();
  }, [details]);

  function onDLChange(index, key, value) {
    setDetails((prevState) => {
      return prevState.map((detail, idx) =>
        idx === index
          ? {
              ...detail,
              [key]: value,
            }
          : detail
      );
    });
  }

  function isValid(data) {
    let _isValid = true;

    if (
      (data.advanceMax || data.advanceMax === 0) &&
      (data.advanceMin || data.advanceMin === 0)
    ) {
      _isValid =
        data.advanceMax !== "" &&
        data.advanceMin !== "" &&
        (data.advanceMax >= data.advanceMin || data.advanceMax === -1);
    }

    return (
      _isValid &&
      data.occMin !== "" &&
      data.occMax !== "" &&
      data.demandLevel !== "" &&
      (data.occMax >= data.occMin || data.occMax === -1)
    );
  }

  function toggleMidweekDLEdit() {
    setIsInEditMode(!isInEditMode);
  }

  function handleCloseStatus() {
    setOpen(false);
  }

  const onDLRemoveRowHandler = (index) => {
    if (details[index].advanceMax) {
      details[index + 1] = {
        ...details[index + 1],
        advanceMax: details[index].advanceMax,
        advanceMin: details[index].advanceMin,
      };
      setDetails((prevState) => {
        return prevState.filter((_, idx) => idx !== index);
      });
    } else {
      setDetails((prevState) => {
        return prevState.filter((_, idx) => idx !== index);
      });
    }
  };
  const onDLAdvRemoveRowHandle = (index) => {
    setDetails((prevState) => {
      return prevState.filter((_, idx) => {
        return prevState[idx].index !== prevState[index].index;
      });
    });
  };
  const onDLAddRowHandler = (index) => {
    const test = [
      ...details.filter((_, idx) => idx <= index),
      {
        occMin: "",
        occMax: "",
        demandLevel: "",
        index: details[index].index,
      },
      ...details.filter((_, idx) => idx > index),
    ];
    setDetails(test);
  };
  const onDLAdvAddRowHandler = (index) => {
    setDetails([
      ...details.filter((_, idx) => details[idx].index <= details[index].index),
      {
        occMin: "",
        occMax: "",
        advanceMax: "",
        advanceMin: "",
        demandLevel: "",
        index: tableConfig.length,
      },
      ...details.filter((_, idx) => details[idx].index > details[index].index),
    ]);
  };
  async function getDLCall(anotherHotelId = false) {
    const hotelIdToUse = anotherHotelId ? anotherHotelId : hotelId;

    const { get } = await authFetch({
      path: `/hotel/${hotelIdToUse}/manual-dl-check`,
    });
    const { data, error } = await get();
    if (data) {
      setDaysCount(data.noOfDays);
      setWeekendDays(data.noOfDaysWeekend);
      setCheck(data.isEnabled);
      setId(data.id);
    }
  }
  async function postDLCall(anotherHotelId = false, dlValue = false, isMidweek = true) {
    const hotelIdToUse = anotherHotelId ? anotherHotelId : hotelId;

    const { post } = await authFetch({
      path: `/hotel/${hotelIdToUse}/manual-dl-check`,
    });
    const { data, error, response } = await post({
      hotelId: parseInt(anotherHotelId ? anotherHotelId : hotelId),
      id: parseInt(idNew),
      isEnabled: isChecked,
      isMidWeek: isMidweek,
      noOfDays: parseInt(days > 0 ? days : dlValue),
      noOfDaysWeekend: parseInt(weekendDays > 0 ? weekendDays : dlValue)
    });
    if (!response?.ok) {
      setnetworkMsg(data?.messageToUser || "Something went wrong");
      setOpen(true);
      console.log(error);
    }
  }
  async function postDLCallNew(anotherHotelId = false, isMidweek = true) {
    const hotelIdToUse = anotherHotelId ? anotherHotelId : hotelId;

    const { post } = await authFetch({
      path: `/hotel/${hotelIdToUse}/manual-dl-check`,
    });
    const { data, error, response } = await post({
      hotelId: parseInt(anotherHotelId ? anotherHotelId : hotelId),
      id: parseInt(idNew),
      isEnabled: isChecked,
      isMidWeek: isMidweek,
      noOfDays: parseInt(days > 0 ? days : 45),
      noOfDaysWeekend: parseInt(weekendDays > 0 ? weekendDays : 45),
    });
    if (!response?.ok) {
      setnetworkMsg(data?.messageToUser || "Something went wrong");
      setOpen(true);
      console.log(error);
    }
  }
  async function refreshDL(anotherHotelId = false) {
    const hotelIdToUse = anotherHotelId ? anotherHotelId : hotelId;
    setLoading(true);
    const { get } = await authFetch({
      path: `/hotel/${hotelIdToUse}/manual-dl-check/${dayType}/all`,
    });
    const { data, error, response } = await get();
    if (response?.ok) {
      setDetails(displayDL(data));
    } else {
      setDetails([]);

      setnetworkMsg(data?.messageToUser || "Something went wrong");
      setOpen(true);
      console.log(error);
    }
    if (error) {
      console.log(error);
    }
    setLoading(false);
    setIsCancelled(false);
    if (anotherHotelId) {
      setIsInEditMode(true);
    }
  }

  function displayDL(data) {
    const dataArray = [];
    if (data) {
      data.forEach((range, index) => {
        dataArray.push({
          advanceMin: range?.advanceMin,
          advanceMax: range?.advanceMax,
          occMin: range?.occupancyRange[0].occMin,
          occMax: range?.occupancyRange[0].occMax,
          demandLevel: range?.occupancyRange[0].demandLevel,
          index,
          range: range?.occupancyRange.length,
        });

        range.occupancyRange
          .slice(1, range.occupancyRange.length)
          .forEach((occ) => {
            dataArray.push({
              occMin: occ.occMin,
              occMax: occ.occMax,
              demandLevel: occ.demandLevel,
              index,
            });
          });
      });
    }

    return dataArray;
  }

  return {
    details,
    isDLInEditMode: isInEditMode,
    setIsDLInEditMode: setIsInEditMode,
    isDLCancelled: isCancelled,
    setIsDLCancelled: setIsCancelled,
    isDLSaved: isSaved,
    setIsDLSaved: setIsSaved,
    onDLChange,
    tableConfig,
    onDLAddRowHandler,
    onDLRemoveRowHandler,
    onDLAdvRemoveRowHandle,
    onDLAdvAddRowHandler,
    loading,
    mdOpen: open,
    mdHandleCloseStatus: handleCloseStatus,
    mdNetworkMsg: networkMsg,
    MidweekDLError: Error,
    checkMidweekValidation,
    checkWeekendValidation,
    days,
    check,
    weekendDays,
    setWeekendDays,
    setDaysCount,
    setCheck,
  };
}
