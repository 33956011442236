import { MenuItem, Tooltip, Modal, Tab, Box as BX, Typography } from "@mui/material";
import { Email } from "@mui/icons-material";
import { Box as BoxPR } from "@mui/material";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import {
  ErrorPage,
  getFullDate,
  getISODate,
  LoadingPage,
  Nodata,
  useAuth,
} from "../sdk";
import { ModelBody } from "./components/EmailModel";
import RecommendationTable from "./components/RecomendationTable";

import { Button, Snackbar, SnackbarContent } from "@mui/material";
import { CalendarToday, Refresh, Schedule } from "@mui/icons-material";
import DatePicker from "react-modern-calendar-datepicker";
import "react-modern-calendar-datepicker/lib/DatePicker.css";
import { styled } from "@mui/system";
import {
  Container,
  EmailIconDisabled,
  GhostButton,
  HeaderCard,
  RecommendationPage,
  ScheduleIconDisabled,
  Select,
} from "./Styles";
import { PreciumDisabledPage } from "../sdk/components/PreciumDiabledPage";
import PushRatesDialog from "./components/PushRatesDialog";
import { useWarning } from "../Provider/context";
function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const StyledPage = styled(RecommendationPage)(({ theme }) => ({
  ".dropdownStyle": {
    background: "#160E39",
    borderRadius: 4,
    color: "white",
  },
  ".icon": {
    fill: "white",
    display: "none",
  },
  ".input": {
    font: "normal normal bold 16px / 20px Roboto",
    color: "#281E53",
    margin: 0,
  },
}));

const EMPTY_DETAIL = {
  name: "",
  postheld: "",
  email: "",
  phoneNumber: "",
  invalidName: false,
  invalidPost: false,
  invalidEmail: false,
  invalidPhone: false,
};
export const HeaderCardNew = styled(BX)`
  display: flex;
  justify-content: center;
  align-items: center;

  color: #000000;
  box-shadow: 0px 4px 4px rgba(48, 111, 188, 0.2);
  border-radius: 8px;
  margin-left: 16px;
  button.MuiButtonBase-root {
    opacity: 1;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;

    span.MuiTab-wrapper {
      font-family: "Roboto";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
      color: #000000;
    }
  }
`;

export const HeaderNew = styled(BX)`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 52px;
  background: #ffffff 0% 0% no-repeat padding-box;
  font: normal normal bold 16px/20px Roboto;
  letter-spacing: 0px;
  color: #281e53;
  margin: auto;
  padding-left: 60px;
`;

export const GhostButtonNew = styled(Button)`
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: center;
  width: 100%;
  height: 100%;
  text-transform: capitalize;
  color: #ffffff;
  &:disabled {
    cursor: auto;
    background-color: transparent;
    color: ${({ disabled }) => (disabled ? "#ffffff" : "#ffffff")};
  }
`;

export const Box = styled(Button)`
  background: #306fbc;
  box-shadow: 0px 4px 4px rgba(3, 4, 94, 0.2);
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  border: none;
  padding: 0px 12px 0px 12px;
  cursor: auto;
`;
export const BoxNew = styled(Box)`
  border: 1px solid red;
  &:hover {
    cursor: not-allowed;
  }
`;

export const CalenderIcon = styled(CalendarToday)`
  position: absolute;
  width: 20px;
  height: 22px;
  color: #306fbc;
  z-index: 101;
  margin-left: -32px;
  margin-top: 4px;
  cursor: pointer;
`;

const PricingRecommendation = ({ setPageHeader }) => {
  const { token, email, authFetch, permission, currentHotel } = useAuth();
  const { hotelId } = useParams();
  const [competitors, setCompetitors] = useState([]);
  const [allFilesForDay, setAllFilesForDay] = useState([]);
  const [networkMsg, setnetworkMsg] = useState(null);
  const [open, setOpen] = useState(false);
  const [recommendedData, setRecommendedData] = useState([]);
  const { DATE, FILEID } = useParams();
  const [openSelectbar, setOpenSelectBar] = useState(false);
  const [manualDemandLevel, setManualDemandLevel] = useState([]);
  const [Loading, setLoading] = useState(false);
  const [contactDetails, setContactDetails] = useState([EMPTY_DETAIL]);
  const [status, setStatus] = useState();
  const [disable, setDisable] = useState(false);
  const [Error, setError] = useState(false);
  const [haveData, setHaveData] = useState(true);
  const { formEdit, setformEdit } = useWarning();
  const [idxForCompetitor, setIdxForCompetitor] = useState(0);
  const [popoverData, setPopOverData] = useState({});
  const [selectedImages, setSelectedImages] = useState([]);
  const [remarkData, setRemarkData] = useState([]);
  const [remarksData, setRemarksData] = useState({});

  const [selectedDate, setSelectedDate] = useState(() => {
    if (!isNaN(new Date(DATE).getTime())) {
      const [year, mon, day] = DATE.split("-");

      if (year && mon && day) {
        if (day && day.length === 1) {
          const validDate = [year, mon, "0" + day].join("-");
          return new Date(validDate);
        } else {
          return new Date(DATE);
        }
      } else {
        return new Date();
      }
    } else {
      return new Date();
    }
  });
  const defaultValue = {
    year: new Date().getFullYear(),
    month: new Date().getMonth() + 1,
    day: new Date().getDate(),
  };
  const [isPushRateDialogOpen, setIsPushRateDialogOpen] = useState(false);
  const [selectedDateNew, setSelectedDateNew] = useState(defaultValue);
  const [pushRateFromDate, setPushRateFromDate] = useState(defaultValue);
  const [pushRateToDate, setPushRateToDate] = useState(defaultValue);
  const [discount, setDiscount] = useState(0);

  const maximumDate = {
    year: new Date().getFullYear(),
    month: new Date().getMonth() + 1,
    day: new Date().getDate(),
  };
  const formatInputValue = () => {
    if (selectedDateNew) {
      const value =
        `${
          selectedDateNew.day < 10
            ? "0" + selectedDateNew.day
            : selectedDateNew.day
        }` +
        "/" +
        `${
          selectedDateNew.month < 10
            ? "0" + selectedDateNew.month
            : selectedDateNew.month
        }` +
        "/" +
        `${selectedDateNew.year}`;
      return value;
    } else {
      if (!isNaN(new Date(DATE).getTime())) {
        const [year, mon, day] = DATE.split("-");

        if (year && mon && day) {
          if (day && day.length === 1) {
            const validDate = "0" + day + "/" + mon + "/" + year;
            return validDate;
          } else {
            const validDate = day + "/" + mon + "/" + year;
            return validDate;
          }
        }
      }
    }
  };
  const getChannelManager = useCallback(async () => {
    try {
      const { get } = await authFetch({
        path: `/hotel/${hotelId}/get-room-rate-plan `,
      });
      const { data, response } = await get();
      if (data && response.status === 200) {
        setDiscount(data[data?.length - 1]?.discount);
      }
    } catch (err) {
      console.log(err);
    }
  }, [authFetch, hotelId]);

  useEffect(() => {
    getChannelManager();
  }, [getChannelManager]);
  
  const RemarksData = useCallback(async () => {
    const yyyymmdd = getISODate(selectedDate).split("T")[0];
    const { get } = await authFetch({
      path: `/hotel/${hotelId}/remarkv2/${yyyymmdd}`,
    });
    const { data, error, response } = await get();
    if (response?.ok) {
      console.log({ data });
      setRemarksData(data);
    } else {
      console.log(error);
    }
  }, [authFetch, hotelId]);

  const RemarkData = async () => {
    const yyyymmdd = getISODate(selectedDate).split("T")[0];
    const { get } = await authFetch({
      path: `/hotel/${hotelId}/remark/${yyyymmdd}`,
    });
    const { data, error } = await get();
    if (data) {
      setRemarkData(data);
      console.log(data, data.length);
    } else {
      console.log(error);
    }
  };

  useEffect(() => {
    RemarkData();
  }, [token]);
  
  useEffect(() => {
    RemarksData();
  }, [RemarksData, token]);

  const uploadImage = async (images) => {
    const UploadSuccess = await Promise.all(
      images.map((file) => {
        return new Promise(async (res) => {
          try {
            const yyyymmdd = getISODate(selectedDate).split("T")[0];
            const formData = new FormData();
            formData.append("date", yyyymmdd);
            formData.append("image", file);
            const { post } = await authFetch({
              path: `/hotel/${hotelId}/upload-image`,
            });
            const { data, error, response } = await post(formData);
            if (!response?.ok) {
              setnetworkMsg(data?.messageToUser || "Failed to upload image");
              setOpen(true);
            } else {
              res({ id: data.id, imageURL: data.imageURL });
            }
          } catch (error) {
            res({ id: "", imageURL: "" });
          }
        });
      })
    );
    return UploadSuccess;
  };

  async function sendRemark(date, popOver) {
    const data = {
      date: date,
      remarkDetails: popOver?.remark?.filter(({isUpdated}) => isUpdated)?.map((item) => ({
        Id : item?.id??0,
        remark: item?.remark,
        uploadIds: item?.remarkUpload?.map((item) => item?.uploadId),
      })),
    };

    const { post } = await authFetch({
      path: `/hotel/${hotelId}/remarkv2`,
    });
    const { data: responseData, error } = await post(data);
    if (data) {
      RemarksData();
      // setnetworkMsg("Upload Successful")
      // setOpen(true);
    }
  }

  useEffect(() => {
    if (selectedDateNew) {
      setSelectedDate(
        new Date(
          [
            selectedDateNew.year,
            selectedDateNew.month,
            selectedDateNew.day.length === 1
              ? "0" + selectedDateNew.day
              : selectedDateNew.day,
          ].join("/")
        )
      );
    }
  }, [selectedDateNew]);
  const renderCustomInput = ({ ref }) => (
    <input
      readOnly
      ref={ref}
      value={formatInputValue()}
      style={{
        textAlign: "left",
        fontSize: "14px",

        borderRadius: "10px",

        color: "#000000",
        outline: "none",
        height: "30px",
        border: "none",
        font: "bold 16px / 20px Roboto",
        cursor: "pointer",
        width: "140px",
        paddingLeft: "14px",
      }}
      className="my-custom-input-class"
    />
  );

  const StyledCalenderWrapper = styled(BoxPR)((theme) => ({
    borderRadius: "8px",
    boxShadow: "0px 4px 4px rgba(48, 111, 188, 0.2)",
    "&:hover>*": {
      color: "#ffffff",
      ">input": {
        backgroundColor: "#306fbc",
        color: "#ffffff !important",
      },
    },
  }));

  useEffect(() => {
    setPageHeader("Price Recommendation");
    return () => {
      setPageHeader("");
    };
  }, []);
  const handleCloseStatus = () => {
    setOpen(false);
  };
  let timer,
    priceRecalculation = null,
    priceOverride = null,
    priceRecommendationWithCalc = null,
    priceRecommendationWithoutCalc = null,
    recalculateInfinite = null;

  const [fileId, setFileId] = useState(() => {
    if (FILEID) {
      return parseInt(FILEID);
    } else {
      return 0;
    }
  });

  const handleChange = (newValue) => {
    setFileId(newValue);
    const file = allFilesForDay.find((file) => file.id === newValue);
    setStatus(file.status);
  };

  const [previousDay, setPreviousDay] = useState(
    selectedDate.toLocaleDateString("en-GB") ===
      new Date().toLocaleDateString("en-GB")
      ? true
      : false
  );

  useEffect(() => {
    setPreviousDay(
      selectedDate.toLocaleDateString("en-GB") ===
        new Date().toLocaleDateString("en-GB")
        ? true
        : false
    );
  }, [selectedDate]);

  const history = useHistory();

  const fetchAllFilesForDay = async () => {
    if (token) {
      setLoading(true);
      const { get } = await authFetch({
        path: `/hotel/${hotelId}/price-recommendation/${
          getISODate(selectedDate).split("T")[0]
        }/all`,
      });
      const { data } = await get();
      if (data && data.length) {
        setAllFilesForDay(
          data
            .sort((a, b) => new Date(b.lastUpdated) - new Date(a.lastUpdated))
            .map((file) => {
              const date = getFullDate(file.lastUpdated);
              let hrs = new Date(
                new Date(file.lastUpdated).getTime()
              ).getHours();
              let mins = new Date(
                new Date(file.lastUpdated).getTime()
              ).getMinutes();
              let sec = new Date(
                new Date(file.lastUpdated).getTime()
              ).getSeconds();
              if (mins < 10) {
                mins = "0" + mins;
              }
              if (sec < 10) {
                sec = "0" + sec;
              }
              if (hrs / 12 > 1) {
                return {
                  ...file,
                  time: date + "  " + (hrs % 12) + ":" + mins + " pm ",
                };
              } else if (hrs / 12 === 1) {
                return {
                  ...file,
                  time: date + "  " + hrs + ":" + mins + " pm ",
                };
              } else {
                return {
                  ...file,
                  time: date + "  " + hrs + ":" + mins + " am ",
                };
              }
            })
        );
        if (data[0].id !== parseInt(FILEID)) {
          const file = [...data].sort(
            (a, b) => new Date(b.lastUpdated) - new Date(a.lastUpdated)
          )[0];
          setFileId(file.id);
          setStatus(file.status);
          setRecommendedData(null);
        } else {
          setFileId(0);
          setFileId(parseInt(FILEID));
          const file = data.find((file) => file.id === parseInt(FILEID));
          setStatus(file?.status);
          setRecommendedData(null);
        }
      } else {
        setAllFilesForDay([]);
        setHaveData(false);
        setFileId(0);
        setRecommendedData([]);
      }
      setLoading(false);
    }
  };

  async function DemandLevelSetup() {
    const { get } = await authFetch({
      path: `/hotel/${hotelId}/city-demand-level`,
    });
    const { data } = await get();
    if (data) {
      setManualDemandLevel(data);
    }
  }

  async function ContactDetailSetup() {
    const { get } = await authFetch({
      path: `/hotel/${hotelId}/contact`,
    });
    const { data } = await get();
    if (data) {
      setContactDetails(data);
    } else {
      setContactDetails([EMPTY_DETAIL]);
    }
  }

  async function CompetitorSetup() {
    const { get } = await authFetch({
      path: `/hotel/${hotelId}/competitors`,
    });
    const { data } = await get();
    if (data) {
      setCompetitors(data);
    }
  }

  const refreshPriceRecommendations = async () => {
    if (token && fileId) {
      setLoading(true);
      const { get } = await authFetch({
        path: `/hotel/${hotelId}/price-recommendation/${fileId}`,
      });
      const { data } = await get();
      if (data && data.pricingDetails) {
        let indexHavingData = 0;
        indexHavingData = data.pricingDetails.findIndex(
          (data, index) => data.competitorDetails
        );
        setIdxForCompetitor(indexHavingData);
        setRecommendedData(data);
      } else {
        setRecommendedData({});
      }
      setLoading(false);
    } else {
      setRecommendedData({});
    }
  };

  for (let key in permission) {
    if (permission.hasOwnProperty(key)) {
      if (permission[key].name === "priceRecommendationWithoutCalc") {
        priceRecommendationWithoutCalc = permission[key];
      }
      if (permission[key].name === "priceRecommendationWithCalc") {
        priceRecommendationWithCalc = permission[key];
      }
      if (permission[key].name === "priceOverride") {
        priceOverride = permission[key];
      }
      if (permission[key].name === "priceRecalculation") {
        priceRecalculation = permission[key];
      }
      if (permission[key].name === "recalculateInfinite") {
        recalculateInfinite = permission[key];
      }
    }
  }

  async function recalculateRecommnedation() {
    if (!recalculateInfinite) {
      setDisable(true);
    }
    setLoading(true);
    try {
      const { get } = await authFetch({
        path: `/hotel/${hotelId}/price-recalculation/${
          getISODate(selectedDate).split("T")[0]
        }`,
      });
      const { data } = await get();
      if (data) {
        setnetworkMsg(data.Message);
        if (!recalculateInfinite) {
          timer = setTimeout(() => setDisable(false), 600000);
        }
        fetchAllFilesForDay();
      }
    } catch (err) {
      setOpen(true);
      setnetworkMsg("Something Went Wrong");
    }

    setLoading(false);
  }

  const pushRatesToChannel = useCallback(
    async (value) => {
      try {
        const frombody = value.data?.map((item) => ({
          amountBeforeTax: {
            Rate:
              discount !== 0
                ? value?.inModal
                  ? item?.pushRate.toString()
                  : ((item?.hotelPrice * 100) / discount).toString()
                : value?.inModal
                ? item.pushRate.toString()
                : item?.hotelPrice?.toString(),
          },
          fromDate: item?.date?.substring(0, 10),
          toDate: item?.date?.substring(0, 10),
          discount: discount,
        }));
        const { post } = await authFetch({
          path: `/hotel/${hotelId}/push-arr-staah`,
        });

        const { data, response } = await post({ data: frombody });
        if (data && response.status === 200) {
          setnetworkMsg("Rates Pushed Successfully");
          setOpen(true);
        } else {
          setnetworkMsg(data?.messageToUser || "Something went wrong");
          setOpen(true);
        }
      } catch (err) {
        console.log(err);
      }
    },
    [authFetch, discount, hotelId]
  );

  const handleClosePushDialog = useCallback(() => {
    setIsPushRateDialogOpen(false);
    setPushRateFromDate(defaultValue);
    setPushRateToDate(defaultValue);
  }, [defaultValue]);

  useEffect(() => {
    console.log("date", { selectedDate, fileId });
    history.replace(
      `/hotel/${hotelId}/price_recommendation/${
        getISODate(selectedDate).split("T")[0]
      }/${fileId}`
    );
  }, [selectedDate, fileId]);
  useEffect(() => {
    if (token && hotelId) {
      window.scrollTo(0, 0);
    } else {
      return;
    }
  }, [token, hotelId]);

  useEffect(() => {
    if (!!hotelId) {
      DemandLevelSetup();
      CompetitorSetup();
      ContactDetailSetup();
    }
  }, [token, hotelId]);

  useEffect(() => {
    if (timer) {
      setTimeout(clearTimeout(timer), 1000);
    }
  }, [timer]);

  useEffect(() => {
    fetchAllFilesForDay();
  }, [token, selectedDate, hotelId]);

  useEffect(() => {
    refreshPriceRecommendations();
  }, [token, fileId, hotelId]);

  return currentHotel?.isPreciumEnabled ? (
    <StyledPage>
      <HeaderNew>
        <HeaderCardNew>
          <label>
            <StyledCalenderWrapper>
              <DatePicker
                value={selectedDateNew}
                onChange={setSelectedDateNew}
                renderInput={renderCustomInput}
                calendarClassName="myCustomCalendar"
                maximumDate={maximumDate}
              />
              <CalenderIcon />
            </StyledCalenderWrapper>
          </label>
        </HeaderCardNew>
        <Snackbar
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          open={open}
          autoHideDuration={3000}
          onClose={handleCloseStatus}
        >
          {networkMsg === "Email Sent Successfully" ||
          networkMsg === "Rates Pushed Successfully" ? (
            <SnackbarContent
              sx={{ backgroundColor: "#228b22" }}
              message={networkMsg}
            />
          ) : (
            <SnackbarContent
              style={{ backgroundColor: "#CA3433" }}
              message={networkMsg}
            />
          )}
        </Snackbar>
        <HeaderCard
          style={{
            flex: 0,

            paddingLeft: "16px",
            paddingRight: "22px",
            boxShadow: "0px 4px 4px rgb(48 111 188 / 20%)",
            borderRadius: "8px",
            marginLeft: "16px",
            height: "32px",
          }}
        >
          {allFilesForDay.length > 0 && (
            <Select
              disableUnderline
              variant="standard"
              IconComponent="none"
              inputProps={{
                classes: {
                  icon: "icon",
                },
              }}
              classes={{
                paper: "dropdownStyle",
              }}
              value={fileId}
              onChange={(e) => handleChange(e.target.value)}
            >
              {allFilesForDay.map((file, index) => (
                <MenuItem value={file.id} key={index}>
                  <Tab
                    label={file.time}
                    value={file.id}
                    {...a11yProps(file.id)}
                  />
                </MenuItem>
              ))}
            </Select>
          )}

          {allFilesForDay.length > 0 ? (
            <Schedule
              style={{
                width: "20px",
                height: "20px",
                marginLeft: "-18px",
                color: "#306fbc",
              }}
            />
          ) : (
            <ScheduleIconDisabled
              style={{
                color: "#6f6f6f",
                width: "20px",
                height: "20px",
              }}
            />
          )}
        </HeaderCard>
        <HeaderCardNew style={{ height: "32px" }}>
          <GhostButton next onClick={() => setOpenSelectBar(true)}>
            {allFilesForDay.length > 0 ? (
              <Email style={{ color: "#306fbc" }} />
            ) : (
              <EmailIconDisabled style={{ color: "#6f6f6f" }} />
            )}
          </GhostButton>
        </HeaderCardNew>
        <HeaderCardNew
          style={{
            background: "#306fbc",
            height: "32px",
            cursor:
              false || !(allFilesForDay.length > 0) ? "not-allowed" : "auto",
          }}
        >
          {priceRecalculation ? (
            <Box
              style={{
                cursor:
                  false || !(allFilesForDay.length > 0)
                    ? "not-allowed"
                    : "pointer",
              }}
            >
              <Refresh
                style={{
                  color: "#ffffff",

                  height: "16px",
                  width: "16px",
                  marginTop: "auto",
                  marginBottom: "auto",
                  cursor: "pointer",
                }}
              />
              <GhostButtonNew
                onClick={() => {
                  setformEdit(false);
                  recalculateRecommnedation();
                }}
                style={{
                  cursor: "pointer",
                  fontWeight: "700",
                }}
              >
                Recalculate Price
              </GhostButtonNew>
            </Box>
          ) : (
            <Tooltip
              disableFocusListener
              disableTouchListener
              title={"Access Denied"}
              placement="right-end"
              arrow
            >
              <Box>
                <Refresh
                  style={{
                    color: "#ffffff",
                    cursor: "not-allowed",
                    pointerEvents: "none",
                    height: "16px",
                    width: "16px",
                    marginTop: "auto",
                    marginBottom: "auto",
                  }}
                />
                <GhostButton
                  next
                  style={{ opacity: "0.38", cursor: "not-allowed" }}
                >
                  Recalculate
                </GhostButton>
              </Box>
            </Tooltip>
          )}
        </HeaderCardNew>
        {priceRecalculation && formEdit ? <Typography minWidth={300} width={200} px={2} fontSize={16} variant="subtitle1" color="error">
          click on Recalculate Price button to get updated price points
        </Typography> : null}
        {currentHotel?.isChannelManagerEnabled && (
          <HeaderCardNew>
            <Button
              variant="contained"
              sx={{
                textTransform: "capitalize",
              }}
              onClick={() => setIsPushRateDialogOpen(true)}
            >
              Push rates
            </Button>
          </HeaderCardNew>
        )}
      </HeaderNew>
      {!Error ? (
        !Loading ? (
          allFilesForDay.length > 0 &&
          recommendedData &&
          recommendedData.pricingDetails ? (
            <>
              <Container>
                <RecommendationTable
                  token={token}
                  currentHotel={currentHotel}
                  competitors={competitors}
                  selectedDate={selectedDate}
                  allFilesForDay={allFilesForDay}
                  setIsRecalculatePrice={setformEdit}
                  fileId={fileId}
                  setnetworkMsg={setnetworkMsg}
                  networkMsg={networkMsg}
                  setOpen={setOpen}
                  open={open}
                  recommendedData={recommendedData}
                  setFileId={setFileId}
                  refreshPriceRecommendations={refreshPriceRecommendations}
                  idx={idxForCompetitor}
                  manualDemandLevel={manualDemandLevel}
                  priceRecommendationWithCalc={priceRecommendationWithCalc}
                  priceOverride={priceOverride}
                  uploadImage={uploadImage}
                  sendRemark={sendRemark}
                  selectedImages={selectedImages}
                  setSelectedImages={setSelectedImages}
                  remarksData={remarksData}
                  setRemarksData={setRemarksData}
                  remarkData={remarkData}
                  setRemarkData={setRemarkData}
                  popoverData={popoverData}
                  setPopOverData={setPopOverData}
                  setIsPushRateDialogOpen={setIsPushRateDialogOpen}
                  setPushRateFromDate={setPushRateFromDate}
                />
              </Container>
              <Modal
                open={openSelectbar}
                onClose={() => setOpenSelectBar(false)}
              >
                <div>
                  <ModelBody
                    fileId={fileId}
                    setOpenSelectBar={setOpenSelectBar}
                    token={token}
                    contactDetails={contactDetails}
                    emailid={email}
                    setnetworkMsg={setnetworkMsg}
                    setOpen={setOpen}
                    setFileId={setFileId}
                  />
                </div>
              </Modal>
              {isPushRateDialogOpen && (
                <PushRatesDialog
                  open={isPushRateDialogOpen}
                  handleClose={handleClosePushDialog}
                  pushRateFromDate={pushRateFromDate}
                  setPushRateFromDate={setPushRateFromDate}
                  pushRateToDate={pushRateToDate}
                  setPushRateToDate={setPushRateToDate}
                  pushRatesToChannel={pushRatesToChannel}
                  recommendedData={recommendedData}
                  discount={discount}
                />
              )}
            </>
          ) : !Error &&
            allFilesForDay.length > 0 &&
            recommendedData === null ? (
            <LoadingPage />
          ) : (
            <Nodata />
          )
        ) : (
          <LoadingPage />
        )
      ) : (
        <ErrorPage />
      )}
    </StyledPage>
  ) : (
    <PreciumDisabledPage />
  );
};

export default PricingRecommendation;
