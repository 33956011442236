import { styled } from "@mui/system";
import { Tooltip, Box, Typography, ClickAwayListener } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import {
  Timeline,
  SwapVertOutlined,
  Settings,
  PieChartRounded,
  LocalOffer,
  InsertChart,
  Event,
  EqualizerOutlined,
  Dashboard,
  Close,
  ChevronRight,
} from "@mui/icons-material";
import { useHistory } from "react-router-dom";
import { useWarning } from "../../Provider/context";
import { useAuth } from "../hooks";
import { PriceChecker } from "../../svgs/PriceChecker";
import { ChannelCompare } from "../../svgs/ChannelComparison";
import { UpcomingEvent } from "../../svgs/UpcomingEvent";
import { V2_ENABLED } from "../constants";
const Side = styled(Box)`
  width: ${(props) => (props.toggle ? "250px" : "60px")};
  height: 100%;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  background: #163a90 0% 0% no-repeat padding-box;
  position: fixed;
  top: 0px;
  left: 0px;
  &::-webkit-scrollbar {
    display: none;
  }
  z-index: 9999;
`;
const TextOnlyTooltip = styled(Tooltip)(({ theme }) => ({
  ".tooltip": {
    color: "#ffffff",
    font: "normal normal bold 16px/20px Roboto",
    boxShadow: "0px 4px 8px #00000035",
    background: "#163A90 !important",
    minWidth: 200,
    textAlign: "left",
  },
  ".arrow": {
    "&:before": {
      border: "1px solid #E6E8ED",
    },
    color: "#163A90 !important",
  },
}));

const LogoHeader = styled(Box)`
  display: flex;
  padding: 22px 24px 0px 24px;
  width: 100%;
`;

const Menu = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  height: 92%;
`;
const P = styled(Typography)`
  font: normal normal normal 16px/20px Roboto;
  letter-spacing: 0px;
  color: #ffffff;
  padding: 0px 24px;

  display: flex;
  align-items: center;
`;

const SideLink = (props) => {
  const { handleRedirect } = useWarning();
  const { children, to, activeStyle, onClick } = props;
  return (
    <Box
      onClick={() => {
        handleRedirect(to);
        onClick();
      }}
      sx={{
        textDecoration: "none",
        width: "100%",
        paddingLeft: "16px",
        height: "40px",
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start",
        color: "white",
        cursor: "pointer",
        marginRight: "18px",
        ...activeStyle,
      }}
    >
      {children}
    </Box>
  );
};

// const TextNoWrap = styled(Typography)`
//   white-space: nowrap;
// `;

const SubMenuLink = (props) => {
  const { handleRedirect } = useWarning();
  const { children, to, activeStyle, style, onClick } = props;
  return (
    <Box
      id="menu-item"
      onClick={(e) => {
        handleRedirect(to);
        onClick(e);
      }}
      sx={{
        textDecoration: "none",
        width: "100%",
        height: "32px",
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start",
        color: "white",
        fontWeight: "normal",
        padding: "0px 5px",
        cursor: "pointer",
        ...style,
        ...activeStyle,
        "&:hover": {
          backgroundColor: "grey !important",
        },
      }}
    >
      {children}
    </Box>
  );
};

const LinkAndIcon = styled(Box)`
  display: flex;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  color: white;
`;
const IconsSidebar = styled(Box)`
  height: 100%;
  background: #163a90 0% 0% no-repeat padding-box;
  width: ${(props) => (props.toggle ? "250px" : "60px")};
  margin-top: 30px;
  color: white;
`;
const MenuIcons = styled(Box)`
  height: 450px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
`;
const Precium = styled(Typography)`
  font: normal normal normal 24px/24px Cochin;
  letter-spacing: 0px;
  color: #ffffff;
`;

const Logo = styled(Box)`
  width: 18px;
  height: 24px;
  margin: 0 8px 0 0;
  &:hover {
    cursor: pointer;
  }
`;

const OpenNCloseMenu = styled(Box)`
  margin: 80px auto 0px auto;
  display: flex;
  justify-content: ${(props) => (props.toggle ? "flex-end" : "center")};
  align-items: center;
  &:hover {
    cursor: pointer;
  }
  width: 100%;
  padding-right: ${(props) => (props.toggle ? "20px" : "0px")};
`;
const SideBar = styled(Box)`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`;

const SelectedMenu = styled(Box)`
  height: 40px;
  width: 100%;
  display: flex;
  align-items: center;
  padding-left: 16px;
`;

const BottomSection = styled(Box)`
  display: flex;
`;

export const Sidebar = ({ isSidebarOpen, handleSidebar }) => {
  const history = useHistory();
  const {
    hotelSelect,
    selectHotel,
    permission,
    version,
    rateShopFeature,
    state,
    currentHotel,
  } = useAuth();

  const hotelId = hotelSelect?.id || null;
  const { formEdit, setformEdit, handleRedirect } = useWarning();
  const [selected, setSelected] = useState(
    window.location.pathname.indexOf("analytics") > -1
  );
  const [forecastSelected, setForecastSelected] = useState(
    window.location.pathname.indexOf("forecast") > -1
  );
  useEffect(() => {
    if (window.location.pathname.indexOf("forecast") > -1) {
      setSelected(false);
      setForecastSelected(true);
    }
    if (window.location.pathname.indexOf("analytics") > -1) {
      setForecastSelected(false);
      setSelected(true);
    }
    if (
      window.location.pathname.indexOf("forecast") === -1 &&
      window.location.pathname.indexOf("analytics") === -1
    ) {
      setSelected(false);
      setForecastSelected(false);
    }
  }, [window.location.pathname]);
  const wrapperRef = useRef(null);
  const [open, setOpen] = React.useState(false);

  const handleTooltipClose = () => {
    setOpen(false);
  };
  const handleTooltipOpen = () => {
    setOpen(true);
  };

  let dashboard = null,
    forecastView = null,
    compPriceView = null,
    PUReport = null,
    analytics = null,
    pastDataView = null,
    priceRecommendationWithoutCalc = null,
    eventsCalendarView = null,
    setupView = null,
    showRateshop = null,
    viewRateParity = null,
    viewUpcomingEvents = null;
  useEffect(() => {
    const handleClickOutside = (e) => {
      if (
        wrapperRef &&
        wrapperRef.current &&
        !wrapperRef.current.contains(e.target)
      ) {
        if (isSidebarOpen) {
          handleSidebar();
        }
      }
    };
    document.addEventListener("click", handleClickOutside);
    return () => {
      if (document) {
        document.removeEventListener("click", handleClickOutside);
      }
    };
  }, [wrapperRef.current, isSidebarOpen]);

  for (let key in permission) {
    if (permission.hasOwnProperty(key)) {
      if (permission[key].name === "dashboard") {
        dashboard = permission[key];
      }
      if (permission[key].name === "forecastView") {
        forecastView = permission[key];
      }
      if (permission[key].name === "compPriceView") {
        compPriceView = permission[key];
      }
      if (permission[key].name === "PUReport") {
        PUReport = permission[key];
      }
      if (permission[key].name === "analytics") {
        analytics = permission[key];
      }
      if (permission[key].name === "pastDataView") {
        pastDataView = permission[key];
      }
      if (permission[key].name === "priceRecommendationWithoutCalc") {
        priceRecommendationWithoutCalc = permission[key];
      }
      if (permission[key].name === "eventsCalendarView") {
        eventsCalendarView = permission[key];
      }
      if (permission[key].name === "hotelDetailsView") {
        setupView = permission[key];
      }
      if (permission[key].name === "showRateshop") {
        showRateshop = permission[key];
      }
      if (permission[key].name === "viewUpcomingEvents") {
        viewUpcomingEvents = permission[key];
      }
      if (permission[key].name === "viewRateParity") {
        viewRateParity = permission[key];
      }
    }
  }

  return hotelSelect ? (
    <Side ref={wrapperRef} toggle={isSidebarOpen}>
      <LogoHeader>
        <Logo
          onClick={() => {
            selectHotel(null);
          }}
        >
          <img
            src="/assets/logo-white.svg"
            alt="Logo"
            width="18px"
            height="24px"
          />
        </Logo>
        {isSidebarOpen && <Precium>Precium</Precium>}
      </LogoHeader>

      <Menu>
        <IconsSidebar toggle={isSidebarOpen}>
          <SideBar>
            {isSidebarOpen ? (
              <MenuIcons>
                {dashboard && (
                  <SideLink
                    exact
                    to={`/hotel/${hotelId}/dashboard`}
                    activeStyle={
                      history.location.pathname.includes("/dashboard")
                        ? {
                            textDecoration: "none",
                            backgroundColor: "grey",
                          }
                        : {}
                    }
                    onClick={() => {
                      setSelected(false);
                      setForecastSelected(false);
                    }}
                  >
                    <LinkAndIcon>
                      <Dashboard />
                      <P>Dashboard</P>
                    </LinkAndIcon>
                  </SideLink>
                )}
                {forecastView && (
                  <SideLink
                    to={`/hotel/${hotelId}/business_on_books`}
                    activeStyle={
                      history.location.pathname.includes("/business_on_books")
                        ? {
                            textDecoration: "none",
                            backgroundColor: "grey",
                          }
                        : {}
                    }
                    onClick={() => {
                      setSelected(false);
                      setForecastSelected(false);
                    }}
                  >
                    <LinkAndIcon>
                      <Timeline />
                      <P>B.O.B Upload</P>
                    </LinkAndIcon>
                  </SideLink>
                )}

                {/* {currentHotel?.isChannelManagerEnabled && (
                  <SideLink
                    to={`/hotel/${hotelId}/bookings`}
                    activeStyle={
                      history.location.pathname.includes("/bookings")
                        ? {
                            textDecoration: "none",
                            backgroundColor: "grey",
                          }
                        : {}
                    }
                    onClick={() => {
                      setSelected(false);
                      setForecastSelected(false);
                    }}
                  >
                    <LinkAndIcon>
                      <img
                        src="/assets/booking-icon.svg"
                        alt="bookings"
                        style={{ color: "fill" }}
                      />
                      <P>Bookings</P>
                    </LinkAndIcon>
                  </SideLink>
                )} */}

                {compPriceView && (
                  <SideLink
                    to={`/hotel/${hotelId}/rate_comparison`}
                    activeStyle={
                      history.location.pathname.includes("/rate_comparison")
                        ? {
                            textDecoration: "none",
                            backgroundColor: "grey",
                          }
                        : {}
                    }
                    onClick={() => {
                      setSelected(false);
                      setForecastSelected(false);
                    }}
                  >
                    <LinkAndIcon>
                      <EqualizerOutlined />
                      <P>Rate Comparison</P>
                    </LinkAndIcon>
                  </SideLink>
                )}
                {PUReport && (
                  <SideLink
                    to={`/hotel/${hotelId}/pickup_report`}
                    activeStyle={
                      history.location.pathname.includes("/pickup_report")
                        ? {
                            textDecoration: "none",
                            backgroundColor: "grey",
                          }
                        : {}
                    }
                    onClick={() => {
                      setSelected(false);
                      setForecastSelected(false);
                    }}
                  >
                    <LinkAndIcon>
                      <SwapVertOutlined />
                      <P>Pick-Up Report</P>
                    </LinkAndIcon>
                  </SideLink>
                )}
                {priceRecommendationWithoutCalc && (
                  <SideLink
                    to={`/hotel/${hotelId}/price_recommendation`}
                    activeStyle={
                      history.location.pathname.includes(
                        "/price_recommendation"
                      )
                        ? {
                            textDecoration: "none",
                            backgroundColor: "grey",
                          }
                        : {}
                    }
                    onClick={() => {
                      setSelected(false);
                      setForecastSelected(false);
                    }}
                  >
                    <LinkAndIcon>
                      <LocalOffer />
                      <P>Pricing&nbsp;Recommendation</P>
                    </LinkAndIcon>
                  </SideLink>
                )}

                {eventsCalendarView && (
                  <SideLink
                    exact
                    to={`/hotel/${hotelId}/new_event/calendar`}
                    activeStyle={
                      history.location.pathname.includes("/calendar")
                        ? {
                            textDecoration: "none",
                            backgroundColor: "grey",
                          }
                        : {}
                    }
                    onClick={() => {
                      setSelected(false);
                      setForecastSelected(false);
                    }}
                  >
                    <LinkAndIcon>
                      <Event />
                      <P>Events&nbsp;Calendar</P>
                    </LinkAndIcon>
                  </SideLink>
                )}

                {currentHotel?.canViewUpcomingEvents && viewUpcomingEvents && (
                  <SideLink
                    exact
                    to={`/hotel/${hotelId}/event-details`}
                    activeStyle={
                      history.location.pathname.includes("/event-details")
                        ? {
                            textDecoration: "none",
                            backgroundColor: "grey",
                          }
                        : {}
                    }
                    onClick={() => {
                      setSelected(false);
                      setForecastSelected(false);
                    }}
                  >
                    <LinkAndIcon>
                      <UpcomingEvent />
                      <P>Upcoming&nbsp;Events</P>
                    </LinkAndIcon>
                  </SideLink>
                )}

                {analytics && (
                  <SelectedMenu
                    style={{
                      backgroundColor: selected ? "grey" : "inherit",
                      marginRight: "12px",
                    }}
                  >
                    <TextOnlyTooltip
                      disableFocusListener
                      interactive
                      title={
                        <div>
                          <SubMenuLink
                            to={`/hotel/${hotelId}/analytics/pace_analysis`}
                            style={{
                              fontWeight: "bold",
                              borderBottom: "0.5px solid white",
                            }}
                            activeStyle={
                              history.location.pathname.includes(
                                "/pace_analysis"
                              )
                                ? {
                                    textDecoration: "none",
                                    backgroundColor: "grey",
                                  }
                                : {}
                            }
                            onClick={() => {
                              setSelected(true);
                              setForecastSelected(false);
                            }}
                          >
                            Analytics
                          </SubMenuLink>
                          <SubMenuLink
                            to={`/hotel/${hotelId}/analytics/pace_analysis`}
                            onClick={() => {
                              setSelected(true);
                              setForecastSelected(false);
                            }}
                            activeStyle={
                              history.location.pathname.includes(
                                "/pace_analysis"
                              )
                                ? {
                                    textDecoration: "none",
                                    backgroundColor: "grey",
                                  }
                                : {}
                            }
                          >
                            Pace Analysis
                          </SubMenuLink>
                          <SubMenuLink
                            to={`/hotel/${hotelId}/analytics/dow_analysis`}
                            activeStyle={
                              history.location.pathname.includes(
                                "/dow_analysis"
                              )
                                ? {
                                    textDecoration: "none",
                                    backgroundColor: "grey",
                                  }
                                : {}
                            }
                            onClick={() => {
                              setSelected(true);
                              setForecastSelected(false);
                            }}
                          >
                            DOW Analysis
                          </SubMenuLink>
                          <SubMenuLink
                            to={`/hotel/${hotelId}/analytics/lead_time_vs_bob`}
                            activeStyle={
                              history.location.pathname === "/lead_time_vs_bob"
                                ? {
                                    textDecoration: "none",
                                    backgroundColor: "grey",
                                  }
                                : {}
                            }
                            onClick={() => {
                              setSelected(true);
                              setForecastSelected(false);
                            }}
                          >
                            Lead Time - BoB Daily
                          </SubMenuLink>
                          <SubMenuLink
                            to={`/hotel/${hotelId}/analytics/lead_time_vs_bob_dow`}
                            activeStyle={
                              history.location.pathname ===
                              "/lead_time_vs_bob_dow"
                                ? {
                                    textDecoration: "none",
                                    backgroundColor: "grey",
                                  }
                                : {}
                            }
                            exact={true}
                            onClick={() => {
                              setSelected(true);
                              setForecastSelected(false);
                            }}
                          >
                            Lead Time - BoB Monthly
                          </SubMenuLink>
                          <SubMenuLink
                            to={`/hotel/${hotelId}/analytics/lead_time_vs_pickup`}
                            activeStyle={
                              history.location.pathname.includes(
                                "/lead_time_vs_pickup"
                              )
                                ? {
                                    textDecoration: "none",
                                    backgroundColor: "grey",
                                  }
                                : {}
                            }
                            onClick={() => {
                              setSelected(true);
                              setForecastSelected(false);
                            }}
                          >
                            Lead Time - Pick-Up Daily
                          </SubMenuLink>
                          <SubMenuLink
                            to={`/hotel/${hotelId}/analytics/lead_time_vs_pickup_dow`}
                            activeStyle={
                              history.location.pathname.includes(
                                "/lead_time_vs_pickup_dow"
                              )
                                ? {
                                    textDecoration: "none",
                                    backgroundColor: "grey",
                                  }
                                : {}
                            }
                            onClick={() => {
                              setSelected(true);
                              setForecastSelected(false);
                            }}
                          >
                            Lead Time - Pickup Monthly
                          </SubMenuLink>
                          <SubMenuLink
                            to={`/hotel/${hotelId}/analytics/dow_pickup_performance`}
                            activeStyle={
                              history.location.pathname.includes(
                                "/dow_pickup_performance"
                              )
                                ? {
                                    textDecoration: "none",
                                    backgroundColor: "grey",
                                  }
                                : {}
                            }
                            onClick={() => {
                              setSelected(true);
                              setForecastSelected(false);
                            }}
                          >
                            DoW Pick-Up Performace
                          </SubMenuLink>
                        </div>
                      }
                      placement="right"
                    >
                      <LinkAndIcon style={{ cursor: "pointer" }}>
                        <PieChartRounded />
                        <P>Analytics</P>
                        <ChevronRight />
                      </LinkAndIcon>
                    </TextOnlyTooltip>
                  </SelectedMenu>
                )}
                {pastDataView && (
                  <SideLink
                    exact
                    to={`/hotel/${hotelId}/pastdata`}
                    activeStyle={
                      history.location.pathname.includes("/pastdata")
                        ? {
                            textDecoration: "none",
                            backgroundColor: "grey",
                          }
                        : {}
                    }
                    onClick={() => {
                      setSelected(false);
                      setForecastSelected(false);
                    }}
                  >
                    <LinkAndIcon>
                      <InsertChart />
                      <P>Past Data</P>
                    </LinkAndIcon>
                  </SideLink>
                )}
                {rateShopFeature && showRateshop ? (
                  <>
                    <SideLink
                      exact
                      to={`/hotel/${hotelId}/price-checker`}
                      activeStyle={
                        history.location.pathname.includes("/price-checker")
                          ? {
                              textDecoration: "none",
                              backgroundColor: "grey",
                            }
                          : {}
                      }
                      onClick={() => {
                        setSelected(false);
                        setForecastSelected(false);
                      }}
                    >
                      <LinkAndIcon>
                        <PriceChecker color="white" />
                        <P>Rate Shopping</P>
                      </LinkAndIcon>
                    </SideLink>
                  </>
                ) : null}
                {rateShopFeature && viewRateParity ? (
                  <SideLink
                    exact
                    to={`/hotel/${hotelId}/channel-comparison`}
                    activeStyle={
                      history.location.pathname.includes("/channel-comparison")
                        ? {
                            textDecoration: "none",
                            backgroundColor: "grey",
                          }
                        : {}
                    }
                    onClick={() => {
                      setSelected(false);
                      setForecastSelected(false);
                    }}
                  >
                    <LinkAndIcon>
                      <ChannelCompare color="white" />
                      <P>Rate Parity</P>
                    </LinkAndIcon>
                  </SideLink>
                ) : null}

                {version === "v1" ? (
                  ""
                ) : version === "v2" ? (
                  <SelectedMenu
                    style={{
                      backgroundColor: forecastSelected ? "grey" : "inherit",
                    }}
                  >
                    <TextOnlyTooltip
                      disableFocusListener
                      interactive
                      title={
                        <div>
                          <SubMenuLink
                            to={`/hotel/${hotelId}/forecast/forecast_sheet_daily`}
                            style={{
                              fontWeight: "bold",
                              borderBottom: "0.5px solid white",
                            }}
                            activeStyle={{
                              textDecoration: "none",
                            }}
                            onClick={() => {
                              setSelected(false);
                              setForecastSelected(true);
                            }}
                          >
                            Budget Forecast
                          </SubMenuLink>
                          <SubMenuLink
                            to={`/hotel/${hotelId}/forecast/pickup`}
                            activeStyle={
                              history.location.pathname.includes("/pickup")
                                ? {
                                    textDecoration: "none",
                                    backgroundColor: "grey",
                                  }
                                : {}
                            }
                            onClick={() => {
                              setSelected(false);
                              setForecastSelected(true);
                            }}
                          >
                            PickUp
                          </SubMenuLink>
                          <SubMenuLink
                            to={`/hotel/${hotelId}/forecast/forecast_sheet_upload`}
                            activeStyle={
                              history.location.pathname.includes(
                                "/forecast_sheet_upload"
                              )
                                ? {
                                    textDecoration: "none",
                                    backgroundColor: "grey",
                                  }
                                : {}
                            }
                            onClick={() => {
                              setSelected(false);
                              setForecastSelected(true);
                            }}
                          >
                            {/* Budget Forecast Upload */}
                            BOB Upload
                          </SubMenuLink>
                          <SubMenuLink
                            to={`/hotel/${hotelId}/forecast/forecast_sheet_daily`}
                            activeStyle={
                              history.location.pathname.includes(
                                "/forecast_sheet_daily"
                              )
                                ? {
                                    textDecoration: "none",
                                    backgroundColor: "grey",
                                  }
                                : {}
                            }
                            onClick={() => {
                              setSelected(false);
                              setForecastSelected(true);
                            }}
                          >
                            Forecast Daily
                          </SubMenuLink>
                          <SubMenuLink
                            to={`/hotel/${hotelId}/forecast/forecast_sheet_monthly`}
                            activeStyle={
                              history.location.pathname.includes(
                                "/forecast_sheet_monthly"
                              )
                                ? {
                                    textDecoration: "none",
                                    backgroundColor: "grey",
                                  }
                                : {}
                            }
                            onClick={() => {
                              setSelected(false);
                              setForecastSelected(true);
                            }}
                          >
                            Forecast Monthly
                          </SubMenuLink>
                          <SubMenuLink
                            to={`/hotel/${hotelId}/forecast/pastdata`}
                            activeStyle={
                              history.location.pathname.includes("/pastdata")
                                ? {
                                    textDecoration: "none",
                                    backgroundColor: "grey",
                                  }
                                : {}
                            }
                            onClick={() => {
                              setSelected(false);
                              setForecastSelected(true);
                            }}
                          >
                            Past Data(Market Segment)
                          </SubMenuLink>
                        </div>
                      }
                      placement="right"
                      arrow
                    >
                      <LinkAndIcon
                        style={{
                          cursor: "pointer",
                        }}
                      >
                        <img
                          src="/assets/ForecastIcon.svg"
                          alt="forecast"
                          width="24px"
                        />
                        <P>Forecast Sheet</P>
                        <ChevronRight />
                      </LinkAndIcon>
                    </TextOnlyTooltip>
                  </SelectedMenu>
                ) : (
                  ""
                )}
                {setupView && (
                  <SideLink
                    to={`/hotel/${hotelId}/setup`}
                    activeStyle={
                      history.location.pathname.includes("/setup")
                        ? {
                            textDecoration: "none",
                            backgroundColor: "grey",
                          }
                        : {}
                    }
                    onClick={() => {
                      setSelected(false);
                      setForecastSelected(true);
                    }}
                  >
                    <LinkAndIcon>
                      <Settings />
                      <P>Setup</P>
                    </LinkAndIcon>
                  </SideLink>
                )}
              </MenuIcons>
            ) : (
              <MenuIcons>
                {dashboard && (
                  <TextOnlyTooltip
                    disableFocusListener
                    title={"Dashboard"}
                    placement="right-end"
                    arrow
                  >
                    <div>
                      <SideLink
                        exact
                        to={`/hotel/${hotelId}/dashboard`}
                        activeStyle={
                          history.location.pathname.includes("/dashboard")
                            ? {
                                textDecoration: "none",
                                backgroundColor: "grey",
                              }
                            : {}
                        }
                        onClick={() => {
                          setSelected(true);
                          setForecastSelected(false);
                          handleRedirect(`/hotel/${hotelId}/dashboard`);
                        }}
                      >
                        <LinkAndIcon>
                          <Dashboard />
                        </LinkAndIcon>
                      </SideLink>
                    </div>
                  </TextOnlyTooltip>
                )}
                {forecastView && (
                  <TextOnlyTooltip
                    disableFocusListener
                    title={"Business on Books"}
                    placement="right-end"
                    arrow
                  >
                    <div>
                      <SideLink
                        to={`/hotel/${hotelId}/business_on_books`}
                        activeStyle={
                          history.location.pathname.includes(
                            "/business_on_books"
                          )
                            ? {
                                textDecoration: "none",
                                backgroundColor: "grey",
                              }
                            : {}
                        }
                        onClick={() => {
                          setSelected(false);
                          setForecastSelected(false);
                        }}
                      >
                        <LinkAndIcon>
                          <Timeline />
                        </LinkAndIcon>
                      </SideLink>
                    </div>
                  </TextOnlyTooltip>
                )}
                {/* {currentHotel?.isChannelManagerEnabled && (
                  <TextOnlyTooltip
                    disableFocusListener
                    title={"Bookings"}
                    placement="right-end"
                    arrow
                  >
                    <div>
                      <SideLink
                        to={`/hotel/${hotelId}/bookings`}
                        activeStyle={
                          history.location.pathname.includes("/bookings")
                            ? {
                                textDecoration: "none",
                                backgroundColor: "grey",
                              }
                            : {}
                        }
                        onClick={() => {
                          setSelected(false);
                          setForecastSelected(false);
                        }}
                      >
                        <LinkAndIcon>
                          <img
                            src="/assets/booking-icon.svg"
                            alt="bookings"
                            style={{ color: "fill" }}
                          />
                        </LinkAndIcon>
                      </SideLink>
                    </div>
                  </TextOnlyTooltip>
                )} */}
                {compPriceView && (
                  <TextOnlyTooltip
                    disableFocusListener
                    title={"Rate Comparison"}
                    placement="right-end"
                    arrow
                  >
                    <div>
                      <SideLink
                        to={`/hotel/${hotelId}/rate_comparison`}
                        activeStyle={
                          history.location.pathname.includes("/rate_comparison")
                            ? {
                                textDecoration: "none",
                                backgroundColor: "grey",
                              }
                            : {}
                        }
                        onClick={() => {
                          setSelected(false);
                          setForecastSelected(false);
                        }}
                      >
                        <LinkAndIcon>
                          <EqualizerOutlined />
                        </LinkAndIcon>
                      </SideLink>
                    </div>
                  </TextOnlyTooltip>
                )}
                {PUReport && (
                  <TextOnlyTooltip
                    disableFocusListener
                    title={"Pick-Up Report"}
                    placement="right-end"
                    arrow
                  >
                    <div>
                      <SideLink
                        to={`/hotel/${hotelId}/pickup_report`}
                        activeStyle={
                          history.location.pathname.includes("/pickup_report")
                            ? {
                                textDecoration: "none",
                                backgroundColor: "grey",
                              }
                            : {}
                        }
                        onClick={() => {
                          setSelected(false);
                          setForecastSelected(false);
                        }}
                      >
                        <LinkAndIcon>
                          <SwapVertOutlined />
                        </LinkAndIcon>
                      </SideLink>
                    </div>
                  </TextOnlyTooltip>
                )}
                {priceRecommendationWithoutCalc && (
                  <TextOnlyTooltip
                    disableFocusListener
                    title={"Price Recommendation"}
                    placement="right-end"
                    arrow
                  >
                    <div>
                      <SideLink
                        to={`/hotel/${hotelId}/price_recommendation`}
                        activeStyle={
                          history.location.pathname.includes(
                            "/price_recommendation"
                          )
                            ? {
                                textDecoration: "none",
                                backgroundColor: "grey",
                              }
                            : {}
                        }
                        onClick={() => {
                          setSelected(false);
                          setForecastSelected(false);
                        }}
                      >
                        <LinkAndIcon>
                          <LocalOffer />
                        </LinkAndIcon>
                      </SideLink>
                    </div>
                  </TextOnlyTooltip>
                )}

                {eventsCalendarView && (
                  <TextOnlyTooltip
                    disableFocusListener
                    title={"Events Calendar"}
                    placement="right-end"
                    arrow
                  >
                    <div>
                      <SideLink
                        exact
                        to={`/hotel/${hotelId}/new_event/calendar`}
                        activeStyle={
                          history.location.pathname.includes(
                            "/new_event/calendar"
                          )
                            ? {
                                textDecoration: "none",
                                backgroundColor: "grey",
                              }
                            : {}
                        }
                        onClick={() => {
                          setSelected(false);
                          setForecastSelected(false);
                        }}
                      >
                        <LinkAndIcon>
                          <Event />
                        </LinkAndIcon>
                      </SideLink>
                    </div>
                  </TextOnlyTooltip>
                )}
                {currentHotel?.canViewUpcomingEvents && viewUpcomingEvents && (
                  <TextOnlyTooltip
                    disableFocusListener
                    title={"Upcoming Events"}
                    placement="right-end"
                    arrow
                  >
                    <div>
                      <SideLink
                        exact
                        to={`/hotel/${hotelId}/event-details`}
                        activeStyle={
                          history.location.pathname.includes("/event-details")
                            ? {
                                textDecoration: "none",
                                backgroundColor: "grey",
                              }
                            : {}
                        }
                        onClick={() => {
                          setSelected(false);
                          setForecastSelected(false);
                        }}
                      >
                        <LinkAndIcon>
                          <UpcomingEvent />
                        </LinkAndIcon>
                      </SideLink>
                    </div>
                  </TextOnlyTooltip>
                )}

                {analytics && (
                  <ClickAwayListener
                    onClickAway={(e) => {
                      if (e.target.id === "menu-item") return;
                      handleTooltipClose();
                    }}
                  >
                    <TextOnlyTooltip
                      open={open}
                      disableFocusListener
                      interactive
                      PopperProps={{
                        disablePortal: true,
                      }}
                      title={
                        <div style={{ width: "200px" }}>
                          <SubMenuLink
                            to={`/hotel/${hotelId}/analytics/pace_analysis`}
                            style={{
                              fontWeight: "bold",
                              borderBottom: "0.5px solid white",
                            }}
                            onClick={(e) => {
                              setSelected(true);
                              setForecastSelected(false);
                              handleTooltipClose();
                            }}
                          >
                            Analytics
                          </SubMenuLink>
                          <SubMenuLink
                            to={`/hotel/${hotelId}/analytics/pace_analysis`}
                            onClick={() => {
                              setSelected(true);
                              setForecastSelected(false);
                              handleTooltipClose();
                            }}
                            activeStyle={
                              history.location.pathname.includes(
                                "/pace_analysis"
                              )
                                ? {
                                    textDecoration: "none",
                                    backgroundColor: "grey",
                                  }
                                : {}
                            }
                          >
                            Pace Analysis
                          </SubMenuLink>
                          <SubMenuLink
                            to={`/hotel/${hotelId}/analytics/dow_analysis`}
                            activeStyle={
                              history.location.pathname.includes(
                                "/dow_analysis"
                              )
                                ? {
                                    textDecoration: "none",
                                    backgroundColor: "grey",
                                  }
                                : {}
                            }
                            onClick={() => {
                              setSelected(true);
                              setForecastSelected(false);
                              handleTooltipClose();
                            }}
                          >
                            DOW Analysis
                          </SubMenuLink>
                          <SubMenuLink
                            to={`/hotel/${hotelId}/analytics/lead_time_vs_bob_daily`}
                            activeStyle={
                              history.location.pathname.includes(
                                "/lead_time_vs_bob_daily"
                              )
                                ? {
                                    textDecoration: "none",
                                    backgroundColor: "grey",
                                  }
                                : {}
                            }
                            onClick={() => {
                              setSelected(true);
                              setForecastSelected(false);
                              handleTooltipClose();
                            }}
                          >
                            Lead Time - BoB Daily
                          </SubMenuLink>
                          <SubMenuLink
                            to={`/hotel/${hotelId}/analytics/lead_time_vs_bob_dow`}
                            activeStyle={
                              history.location.pathname.includes(
                                "/lead_time_vs_bob_dow"
                              )
                                ? {
                                    textDecoration: "none",
                                    backgroundColor: "grey",
                                  }
                                : {}
                            }
                            onClick={() => {
                              setSelected(true);
                              setForecastSelected(false);
                              handleTooltipClose();
                            }}
                          >
                            Lead Time - BoB Monthly
                          </SubMenuLink>
                          <SubMenuLink
                            to={`/hotel/${hotelId}/analytics/lead_time_vs_pickup_daily`}
                            activeStyle={
                              history.location.pathname.includes(
                                "/lead_time_vs_pickup_daily"
                              )
                                ? {
                                    textDecoration: "none",
                                    backgroundColor: "grey",
                                  }
                                : {}
                            }
                            onClick={() => {
                              setSelected(true);
                              setForecastSelected(false);
                              handleTooltipClose();
                            }}
                          >
                            Lead Time - Pick-Up Daily
                          </SubMenuLink>
                          <SubMenuLink
                            to={`/hotel/${hotelId}/analytics/lead_time_vs_pickup_dow`}
                            activeStyle={
                              history.location.pathname.includes(
                                "/lead_time_vs_pickup_dow"
                              )
                                ? {
                                    textDecoration: "none",
                                    backgroundColor: "grey",
                                  }
                                : {}
                            }
                            onClick={() => {
                              setSelected(true);
                              setForecastSelected(false);
                              handleTooltipClose();
                            }}
                          >
                            Lead Time - Pick-Up Monthly
                          </SubMenuLink>
                          <SubMenuLink
                            to={`/hotel/${hotelId}/analytics/dow_pickup_performance`}
                            activeStyle={
                              history.location.pathname.includes(
                                "/dow_pickup_performance"
                              )
                                ? {
                                    textDecoration: "none",
                                    backgroundColor: "grey",
                                  }
                                : {}
                            }
                            onClick={() => {
                              setSelected(true);
                              setForecastSelected(false);
                              handleTooltipClose();
                            }}
                          >
                            DoW Pick-Up Performance
                          </SubMenuLink>
                        </div>
                      }
                      placement="right"
                      arrow
                    >
                      <LinkAndIcon
                        onClick={handleTooltipOpen}
                        style={{
                          paddingLeft: "16px",
                          height: "40px",
                          cursor: "pointer",
                          backgroundColor: selected ? "grey" : "inherit",
                        }}
                      >
                        <PieChartRounded />
                      </LinkAndIcon>
                    </TextOnlyTooltip>
                  </ClickAwayListener>
                )}
                {pastDataView && (
                  <TextOnlyTooltip
                    disableFocusListener
                    title={"Past Data"}
                    placement="right-end"
                    arrow
                  >
                    <div>
                      <SideLink
                        exact
                        to={`/hotel/${hotelId}/pastdata`}
                        activeStyle={
                          history.location.pathname.includes("/pastdata")
                            ? {
                                textDecoration: "none",
                                backgroundColor: "grey",
                              }
                            : {}
                        }
                        onClick={() => {
                          setSelected(false);
                          setForecastSelected(false);
                        }}
                      >
                        <LinkAndIcon>
                          <InsertChart />
                        </LinkAndIcon>
                      </SideLink>
                    </div>
                  </TextOnlyTooltip>
                )}

                {version === "v1" ? (
                  ""
                ) : version === "v2" ? (
                  <TextOnlyTooltip
                    disableFocusListener
                    interactive
                    title={
                      <div>
                        <SubMenuLink
                          to={`/hotel/${hotelId}/forecast/forecast_sheet_daily`}
                          style={{
                            fontWeight: "bold",
                            borderBottom: "0.5px solid white",
                          }}
                          activeStyle={{
                            textDecoration: "none",
                          }}
                          onClick={() => {
                            setSelected(false);
                            setForecastSelected(true);
                          }}
                        >
                          Budget Forecast
                        </SubMenuLink>

                        <SubMenuLink
                          to={`/hotel/${hotelId}/forecast/pickup`}
                          activeStyle={
                            history.location.pathname.includes("/pickup")
                              ? {
                                  textDecoration: "none",
                                  backgroundColor: "grey",
                                }
                              : {}
                          }
                          onClick={() => {
                            setSelected(false);
                            setForecastSelected(true);
                          }}
                        >
                          PickUp
                        </SubMenuLink>

                        <SubMenuLink
                          to={`/hotel/${hotelId}/forecast/forecast_sheet_upload`}
                          activeStyle={
                            history.location.pathname.includes(
                              "/forecast_sheet_upload"
                            )
                              ? {
                                  textDecoration: "none",
                                  backgroundColor: "grey",
                                }
                              : {}
                          }
                          onClick={() => {
                            setSelected(false);
                            setForecastSelected(true);
                          }}
                        >
                          {/* Budget Forecast Upload */}
                          BOB Upload
                        </SubMenuLink>
                        <SubMenuLink
                          to={`/hotel/${hotelId}/forecast/forecast_sheet_daily`}
                          activeStyle={
                            history.location.pathname.includes(
                              "/forecast_sheet_daily"
                            )
                              ? {
                                  textDecoration: "none",
                                  backgroundColor: "grey",
                                }
                              : {}
                          }
                          onClick={() => {
                            setSelected(false);
                            setForecastSelected(true);
                          }}
                        >
                          {/* Budget Forecast Daily */}
                          Forecast Daily
                        </SubMenuLink>
                        <SubMenuLink
                          to={`/hotel/${hotelId}/forecast/forecast_sheet_monthly`}
                          activeStyle={
                            history.location.pathname.includes(
                              "/forecast_sheet_monthly"
                            )
                              ? {
                                  textDecoration: "none",
                                  backgroundColor: "grey",
                                }
                              : {}
                          }
                          onClick={() => {
                            setSelected(false);
                            setForecastSelected(true);
                          }}
                        >
                          Forecast Monthly
                        </SubMenuLink>
                        <SubMenuLink
                          to={`/hotel/${hotelId}/forecast/pastdata`}
                          activeStyle={
                            history.location.pathname.includes("/pastdata")
                              ? {
                                  textDecoration: "none",
                                  backgroundColor: "grey",
                                }
                              : {}
                          }
                          onClick={() => {
                            setSelected(false);
                            setForecastSelected(true);
                          }}
                        >
                          Past Data(Market Segment)
                        </SubMenuLink>
                      </div>
                    }
                    placement="right"
                    arrow
                  >
                    <LinkAndIcon
                      style={{
                        paddingLeft: "16px",
                        height: "40px",
                        cursor: "pointer",
                        backgroundColor: forecastSelected ? "grey" : "inherit",
                      }}
                    >
                      <img
                        src="/assets/ForecastIcon.svg"
                        alt="forecast"
                        width="24px"
                      />
                    </LinkAndIcon>
                  </TextOnlyTooltip>
                ) : (
                  ""
                )}
                {rateShopFeature && showRateshop && (
                  <>
                    <TextOnlyTooltip
                      disableFocusListener
                      title={"Rate Shopping"}
                      placement="right-end"
                      arrow
                    >
                      <div>
                        <SideLink
                          exact
                          to={`/hotel/${hotelId}/price-checker`}
                          activeStyle={
                            history.location.pathname.includes("/price-checker")
                              ? {
                                  textDecoration: "none",
                                  backgroundColor: "grey",
                                  paddingLeft: "11px",
                                }
                              : {}
                          }
                          onClick={() => {
                            setSelected(false);
                            setForecastSelected(false);
                          }}
                        >
                          <LinkAndIcon>
                            <PriceChecker color="white" />
                          </LinkAndIcon>
                        </SideLink>
                      </div>
                    </TextOnlyTooltip>
                  </>
                )}
                {rateShopFeature && viewRateParity && (
                  <TextOnlyTooltip
                    disableFocusListener
                    title={"Rate Parity"}
                    placement="right-end"
                    arrow
                  >
                    <div>
                      <SideLink
                        exact
                        to={`/hotel/${hotelId}/channel-comparison`}
                        activeStyle={
                          history.location.pathname.includes(
                            "/channel-comparison"
                          )
                            ? {
                                textDecoration: "none",
                                backgroundColor: "grey",
                                paddingLeft: "11px",
                              }
                            : {}
                        }
                        onClick={() => {
                          setSelected(false);
                          setForecastSelected(false);
                        }}
                      >
                        <LinkAndIcon>
                          <ChannelCompare color="white" />
                        </LinkAndIcon>
                      </SideLink>
                    </div>
                  </TextOnlyTooltip>
                )}
                {setupView && (
                  <TextOnlyTooltip
                    disableFocusListener
                    title={"Setup"}
                    placement="right-end"
                    arrow
                  >
                    <div>
                      <SideLink
                        to={`/hotel/${hotelId}/setup`}
                        activeStyle={
                          history.location.pathname.includes("/setup")
                            ? {
                                textDecoration: "none",
                                backgroundColor: "grey",
                              }
                            : {}
                        }
                        onClick={() => {
                          setSelected(false);
                          setForecastSelected(false);
                          handleRedirect(`/hotel/${hotelId}/setup`);
                        }}
                      >
                        <LinkAndIcon>
                          <Settings />
                        </LinkAndIcon>
                      </SideLink>
                    </div>
                  </TextOnlyTooltip>
                )}
              </MenuIcons>
            )}
            <OpenNCloseMenu toggle={isSidebarOpen}>
              <Logo
                onClick={() => {
                  handleSidebar();
                }}
              >
                <BottomSection>
                  <Close
                    style={{
                      display: !isSidebarOpen ? "none" : "block",
                    }}
                  />
                  <img
                    src="/assets/menu.svg"
                    alt="menu"
                    width="24px"
                    height="24px"
                    style={{
                      display: isSidebarOpen ? "none" : "block",
                    }}
                  />
                </BottomSection>
              </Logo>
            </OpenNCloseMenu>
          </SideBar>
        </IconsSidebar>
      </Menu>
    </Side>
  ) : (
    <div />
  );
};
