import { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useWarning } from "../../Provider/context";
import { useAuth } from "../../sdk/hooks/useAuth";

export const useCompetitor = (
  hotelId,
  rateShopAppEnabled,
  setRateShopAppEnabled
) => {
  const EMPTY_COMPETITOR = {
    displayKey: "",
    otaName: "",
    travelClickName: "",
    rateMetricHotelCode: null,
    expediaName: "",
    ratepingName: "",
    rateGainName: "",
    stars: null,
    rooms: null,
    id: -1,
    sequence: 0,
  };

  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isSaved, setIsSaved] = useState(true);
  const [isOpenDialog, setIsOpenDialog] = useState(false);
  const [dialogSave, setDialogSave] = useState(false);
  const [disable, setDisable] = useState(false);
  const { formEdit, setformEdit, handleRedirect } = useWarning();
  const [myError, setMyError] = useState(false);

  const handleCloseStatus = () => {
    setOpen(false);
  };

  const [networkMsg, setnetworkMsg] = useState(null);
  const [uploadNetworkMsg, setUploadNetworkMsg] = useState({
    open: false,
    color: "",
    msg: "",
  });

  const history = useHistory();
  const [competitorsData, setCompetitorsData] = useState([]);
  const { token, authFetch } = useAuth();
  const [hotelSelect, setHotelSelect] = useState();
  const [allSources, setAllSources] = useState([]);

  //api call
  useEffect(() => {
    async function AllDetailsSetup() {
      const { get } = await authFetch({
        path: `/hotel/${hotelId}`,
      });
      const { data, error } = await get();
      if (data && !error) {
        setHotelSelect(data);
        setRateShopAppEnabled(data?.rateShopEnabled);
      }
    }
    AllDetailsSetup();
  }, [authFetch, hotelId, setRateShopAppEnabled, token]);

  const getSources = useCallback(async () => {
    try {
      setIsLoading(true);
      const { get } = await authFetch({
        path: `/sources`,
      });
      const { data } = await get();
      if (data) {
        const finalSources = data.filter((item) => item.sourceId !== 8);
        setAllSources(finalSources);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  }, [authFetch]);

  useEffect(() => {
    getSources();
  }, [getSources]);

  const [Error, setError] = useState(false);
  function isInvalidEntry(competitor) {
    // return hotelSelect.competitorPricingFileTypeID !== 1 &&
    //   hotelSelect.competitorPricingFileTypeID !== 5 &&
    //   hotelSelect.competitorPricingFileTypeID !== 6
    //   ? !(
    //       competitor.displayKey &&
    //       competitor.expediaName &&
    //       competitor.rooms &&
    //       competitor.ratepingName
    //     )
    //   : hotelSelect.competitorPricingFileTypeID === 5
    //   ? !(competitor.displayKey && competitor.otaName && competitor.rooms)
    //   : hotelSelect.competitorPricingFileTypeID === 6
    //   ? !(
    //       competitor.displayKey &&
    //       competitor.travelClickName &&
    //       competitor.rooms
    //     )
    //   : !(competitor.displayKey && competitor.expediaName && competitor.rooms);

    switch (hotelSelect.competitorPricingFileTypeID) {
      case 1:
        return !(
          competitor.displayKey &&
          competitor.expediaName &&
          competitor.rooms
        );
      case 2:
        return !(
          competitor.displayKey &&
          competitor.ratepingName &&
          competitor.rooms
        );

      case 3:
        return !(
          competitor.displayKey &&
          competitor.expediaName &&
          competitor.rooms &&
          competitor.ratepingName
        );

      case 4:
        return !(competitor.displayKey && competitor.rooms);

      case 5:
        return !(
          competitor.displayKey &&
          competitor.otaName &&
          competitor.rooms
        );

      case 6:
        return !(
          competitor.displayKey &&
          competitor.travelClickName &&
          competitor.rooms
        );

      case 7:
        return !(
          competitor.displayKey &&
          competitor.rateGainName &&
          competitor.rooms
        );
      case 8:
        return !(competitor.displayKey && competitor.rooms);
      default:
        break;
    }
  }
  function isValidEntry(competitor) {
    // return hotelSelect.competitorPricingFileTypeID !== 1 &&
    //   hotelSelect.competitorPricingFileTypeID !== 5 &&
    //   hotelSelect.competitorPricingFileTypeID !== 6
    //   ? competitor.displayKey &&
    //       competitor.expediaName &&
    //       competitor.rooms &&
    //       competitor.ratepingName
    //   : hotelSelect.competitorPricingFileTypeID === 5
    //   ? competitor.displayKey && competitor.otaName && competitor.rooms
    //   : hotelSelect.competitorPricingFileTypeID === 6
    //   ? competitor.displayKey && competitor.travelClickName && competitor.rooms
    //   : competitor.displayKey && competitor.expediaName && competitor.rooms;

    switch (hotelSelect.competitorPricingFileTypeID) {
      case 1:
        return (
          competitor.displayKey && competitor.expediaName && competitor.rooms
        );
      case 2:
        return (
          competitor.displayKey && competitor.ratepingName && competitor.rooms
        );

      case 3:
        return (
          competitor.displayKey &&
          competitor.expediaName &&
          competitor.rooms &&
          competitor.ratepingName
        );

      case 4:
        return competitor.displayKey && competitor.rooms;

      case 5:
        return competitor.displayKey && competitor.otaName && competitor.rooms;

      case 6:
        return (
          competitor.displayKey &&
          competitor.travelClickName &&
          competitor.rooms
        );

      case 7:
        return (
          competitor.displayKey && competitor.rateGainName && competitor.rooms
        );
      case 8:
        return competitor.displayKey && competitor.rooms;
      default:
        break;
    }
  }
  async function GetCompetitors() {
    setIsLoading(true);
    const { get } = await authFetch({
      path: `/hotel/${hotelId}/competitors`,
    });
    const { data, error } = await get();
    if (data) {
      setCompetitorsData([
        ...data.map((val) => {
          return {
            ...val,
          };
        }),
      ]);
      setError(false);
    } else {
      setError(true);
      console.log(error);
    }
    setIsLoading(false);
  }
  useEffect(() => {
    if (hotelId && token) {
      GetCompetitors();
    }
  }, [token, hotelId]);
  /**todo */
  const editHandler = (index) => {
    const invlaidCompetitorEntry = competitorsData.some(isInvalidEntry);

    if (invlaidCompetitorEntry) {
      return;
    }
    setCompetitorsData(
      competitorsData.map((data, idx) => {
        if (idx === index) {
          return {
            ...data,
          };
        }
        return {
          ...data,
        };
      })
    );
  };

  const removeHandler = async (index) => {
    setIsLoading(true);
    if (index) {
      if (index === -1) {
        setDisable(false);
        competitorsData.pop();
        setCompetitorsData(competitorsData);
        setIsLoading(false);
        return;
      }
      const { del } = await authFetch({
        path: `/hotel/${hotelId}/competitors/${index}`,
      });
      const { data, error } = await del();
      if (error) {
        setnetworkMsg("can't delete competitors, try again");
        setOpen(true);
        console.log(error);
      }
      if (!error) {
        GetCompetitors();
      }
    }
    setIsLoading(false);
  };

  const addHotelHandler = useCallback(() => {
    console.log("first");
    setDisable(true);
    setIsSaved(false);

    const invlaidCompetitorEntry = competitorsData.some(isInvalidEntry);
    console.log({ invlaidCompetitorEntry });
    if (invlaidCompetitorEntry) {
      return;
    }

    setCompetitorsData([
      ...competitorsData.map((data, index) => {
        return {
          ...data,
        };
      }),
      {
        ...EMPTY_COMPETITOR,
        sequence: competitorsData.length + 1,
      },
    ]);
  }, [isSaved, EMPTY_COMPETITOR, competitorsData, isInvalidEntry]);

  const doneHotelHandler = (index) => {
    const competitor = competitorsData[index];
    if (
      hotelSelect.competitorPricingFileTypeID !== 1 &&
      !(
        competitor.displayKey &&
        competitor.expediaName &&
        competitor.rooms &&
        competitor.rateMetricHotelCode &&
        competitor.ratepingName
      )
    ) {
      return;
    }
    if (
      hotelSelect.competitorPricingFileTypeID === 1 &&
      competitor.displayKey &&
      competitor.expediaName &&
      competitor.rooms &&
      competitor.rateMetricHotelCode &&
      competitor.ratepingName
    ) {
      return;
    }
    setCompetitorsData(
      competitorsData.map((val, idx) => {
        if (index === idx) {
          return {
            ...val,
          };
        }
        return val;
      })
    );
  };
  const changeHandler = useCallback(
    (index, key, value) => {
      setformEdit(true);
      setIsSaved(false);
      setCompetitorsData((prevState) => {
        return prevState.map((data, idx) => {
          if (index === idx) {
            return {
              ...data,
              [key]: value,
            };
          }
          return data;
        });
      });
    },
    [isSaved]
  );

  const handleCloseDialog = useCallback(() => {
    setIsOpenDialog(false);
  }, [isOpenDialog]);

  const handleNexthandler = useCallback(() => {
    function nextPage() {
      if (isSaved === true) {
        history.push(`/hotel/${hotelId}/setup/room-adjustment`);
      } else {
        setIsOpenDialog(true);
      }
    }
    nextPage();
  }, [isSaved]);

  const handleModalSave = useCallback(() => {
    setDialogSave(true);
  }, [dialogSave]);

  useEffect(() => {
    if (dialogSave) {
      saveCompetitorData();
    }
  }, [dialogSave]);

  const handleLeavePage = useCallback(() => {
    history.push(`/hotel/${hotelId}/setup/room-adjustment`);
  }, [hotelId]);

  const saveCompetitorData = useCallback(async () => {
    setIsLoading(true);
    setMyError(true);
    setformEdit(false);

    const validCompetitors = competitorsData.filter(isValidEntry);
    console.log({ validCompetitors, competitorsData });
    // const { rateMetricHotelCode, ...rest}= validCompetitors;
    if (validCompetitors.length !== competitorsData.length) {
      setnetworkMsg("Fields Cannot be Empty");
      setOpen(true);
      setIsLoading(false);
      return;
    }
    const requestBody = validCompetitors.map((competitor) => {
      if (rateShopAppEnabled) {
        return {
          ...competitor,
        };
      } else {
        const { rateMetricHotelCode, ...rest } = competitor;
        return rest;
      }
    });
    const { post } = await authFetch({
      path: `/hotel/${hotelId}/competitors/all`,
    });
    const { data, error, response } = await post(requestBody);

    if (data) {
      if (dialogSave) {
        history.push(`/hotel/${hotelId}/setup/room-adjustment`);
      }
      setIsSaved(true);

      if (response?.status === 200) {
        setDisable(false);
        setUploadNetworkMsg({
          ...uploadNetworkMsg,
          open: true,
          color: "green",
          msg: "Saved Successfully",
        });
        GetCompetitors();
      }
    } else {
      setError(true);
      setUploadNetworkMsg({
        ...uploadNetworkMsg,
        open: true,
        color: "red",
        msg: data?.messageToUser ?? "Something went wrong, Please try again.",
      });
    }
    setDisable(false);
    setIsLoading(false);
  }, [
    isSaved,
    authFetch,
    hotelId,
    competitorsData,
    isValidEntry,
    uploadNetworkMsg,
  ]);

  const reorderCompetitors = (
    list,
    startIndex,
    endIndex,
    sourceData,
    DestinationData
  ) => {
    const [removed] = list.splice(startIndex, 1);
    list.splice(endIndex, 0, removed);
    reorderCompApi(sourceData, DestinationData);
    return [...list];
  };

  const reorderCompApi = useCallback(
    async (activeId, formerIdx, laterIdx) => {
      const { post } = await authFetch({
        path: `/hotel/${hotelId}/competitors/sequence`,
      });
      const { data, response } = await post({
        formerId: activeId,
        formerSequence: formerIdx,
        laterSequence: laterIdx,
      });

      if (response.status === 204) {
        GetCompetitors();
      } else {
        setError(true);
        setUploadNetworkMsg({
          ...uploadNetworkMsg,
          open: true,
          color: "red",
          msg: data?.messageToUser ?? "Something went wrong",
        });
      }
    },
    [authFetch, hotelId]
  );

  const handleUploadNetwork = useCallback(() => {
    setUploadNetworkMsg({ ...uploadNetworkMsg, open: false });
  }, [uploadNetworkMsg]);

  const [openChannelDialog, setOpenChannelDialog] = useState(false);
  const [availableChannels, setAvailableChannels] = useState([]);
  const [CompAvailableChannels, setCompAvailableChannels] = useState([]);
  const [ischannelSearching, setIsChannelSearching] = useState(false);
  const [selectedCompititor, setSelectedCompitor] = useState("");

  const handleEyeClick = useCallback(
    async (index) => {
      try {
        if (!hotelSelect) return;
        setOpenChannelDialog(true);
        setSelectedCompitor(competitorsData?.[index]?.displayKey);
        setIsChannelSearching(true);

        const { post: compHotelPost } = await authFetch({
          path: `/rate-metric-hotel-info?hotelRateMetricCode=${competitorsData?.[index]?.rateMetricHotelCode}`,
        });
        const { post: myHotelPost } = await authFetch({
          path: `/rate-metric-hotel-info?hotelRateMetricCode=${hotelSelect?.rateMetricHotelCode}`,
        });

        const [compHotelData, myHotelData] = await Promise.all([
          compHotelPost(),
          myHotelPost(),
        ]);

        if (compHotelData?.data && compHotelData?.response?.status === 200) {
          setCompAvailableChannels(compHotelData.data);
        } else {
          setUploadNetworkMsg({
            ...uploadNetworkMsg,
            open: true,
            color: "red",
            msg: compHotelData?.data?.messageToUser ?? "Something went wrong",
          });
        }
        if (myHotelData?.data && myHotelData?.response?.status === 200) {
          setAvailableChannels(myHotelData.data);
        } else {
          setUploadNetworkMsg({
            ...uploadNetworkMsg,
            open: true,
            color: "red",
            msg: myHotelData.data?.messageToUser ?? "Something went wrong",
          });
        }
      } catch (err) {
        console.error("Error fetching data:", err);
      } finally {
        setIsChannelSearching(false);
      }
    },
    [authFetch, competitorsData, hotelSelect]
  );

  const allSortedSources = allSources
    .filter((source) => source.sourceId !== 8)
    .sort((a, b) => a.sourceId - b.sourceId);

  const myHotelSelectedSources = hotelSelect?.sources
    ?.map((item) => {
      const source = allSortedSources.find((source) => source.id === item);
      return source && source.sourceId !== 8 ? source : null;
    })
    .filter((channel) => channel !== null);

  const zippedArray = allSortedSources.map((source) => {
    const myHotelAvailableChannels = availableChannels?.sources?.find(
      (item) => item.id === source.id
    );

    const myHotelSelectedChannel = myHotelSelectedSources?.find(
      (item) => item.id === source.id
    );

    const myCompAvailableChannels = CompAvailableChannels?.sources?.find(
      (item) => item.id === source.id
    );

    return {
      myHotelAvailableChannels: myHotelAvailableChannels
        ? myHotelAvailableChannels?.name
        : "-",
      myCompAvailableChannels: myCompAvailableChannels
        ? myCompAvailableChannels?.name
        : "-",
      myHotelSelectedChannels: myHotelSelectedChannel
        ? myHotelSelectedChannel?.name
        : "-",
    };
  });

  const handleCloseChannelDialog = () => {
    setOpenChannelDialog(false);
    setSelectedCompitor("");

    setAvailableChannels([]);
    setCompAvailableChannels([]);
  };

  return {
    hotelSelect,
    allSources,
    competitorsData,
    setCompetitorsData,
    changeHandler,
    editHandler,
    removeHandler,
    addHotelHandler,
    doneHotelHandler,
    saveCompetitorData,
    handleCloseStatus,
    open,
    networkMsg,
    Error,
    isLoading,
    handleNexthandler,
    isSaved,
    isOpenDialog,
    setIsOpenDialog,
    handleCloseDialog,
    handleLeavePage,
    handleUploadNetwork,
    uploadNetworkMsg,
    handleModalSave,
    reorderCompetitors,
    disable,
    setDisable,
    myError,
    setMyError,
    openChannelDialog,
    setOpenChannelDialog,
    handleEyeClick,
    availableChannels,
    CompAvailableChannels,
    handleCloseChannelDialog,
    ischannelSearching,
    selectedCompititor,
    zippedArray,
    formEdit,
    handleRedirect,
    reorderCompApi,
  };
};
