import CancelIcon from "@mui/icons-material/Cancel";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import WarningIcon from "@mui/icons-material/Warning";
import React, { createContext, useCallback, useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import { PrimaryButton } from "../sdk/components/Button";
import { styled } from "@mui/system";

export const WarningContext = createContext();

export default function WarningProvider({ children }) {
  const history = useHistory();
  const [formEdit, setformEdit] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [url, setUrl] = useState();
  const [idi, setidi] = useState(null);

  const handleRedirect = useCallback(
    (url, id) => {
      if (formEdit) {
        setOpenDialog(true);
        setUrl(url);
        setidi(id);
      } else {
        history.push(url);
      }
    },
    [formEdit, history, openDialog]
  );

  return (
    <WarningContext.Provider value={{ formEdit, setformEdit, handleRedirect }}>
      {children}

      <StyledDialog open={openDialog}>
        <DialogTitle className="content">
          <Typography variant="h6" fontWeight="500">
            Warning
          </Typography>
          <WarningIcon sx={{ color: "#a90f0f", ml: 1 }} />
        </DialogTitle>
        <DialogContent className="content">
          <IconButton
            className="closeButton"
            onClick={() => setOpenDialog((p) => !p)}
          >
            <CancelIcon
              sx={{
                color: "#306fbc",
              }}
            />
          </IconButton>
          <Typography
            sx={{ color: "black" }}
            variant="subtitle1"
            fontWeight={500}
            textAlign={"center"}
          >
            {/* You have unsaved changes. Do you wish to continue? */}
            {
              history.location.pathname.includes("price_recommendation")? 
              "There are unsaved changes in the file. Do you wish to continue without recalculating the price." : 
              "There are unsaved changes. Do you wish to continue?"
            }
            <Typography
              sx={{ color: "black" }}
              variant="subtitle1"
              fontWeight={500}
              textAlign={"center"}
            >
            {
              history.location.pathname.includes("price_recommendation")? 
              "Click no to save the changes. Click yes to continue without recalculating the price" : 
              `Click “No” to save the changes. Click “Yes” to continue without
              saving.`
            }
              
            </Typography>
          </Typography>
        </DialogContent>
        <DialogActions>
          <Stack
            direction={"row"}
            justifyContent={"center"}
            sx={{
              width: "100%",
              margin: "1%",
              marginTop: "0",
            }}
            spacing={3}
          >
            <PrimaryButton
              sx={{
                width: "100%",
                padding: "1%",
                width: "75px",
                height: "32px",
              }}
              onClick={() => {
                setOpenDialog(false);
              }}
            >
              No
            </PrimaryButton>
            <PrimaryButton
              sx={{
                width: "100%",
                padding: "1%",
                width: "75px",
                height: "32px",
              }}
              onClick={() => {
                history.push(url);
                setOpenDialog(false);
                setformEdit(false);
              }}
            >
              Yes
            </PrimaryButton>
          </Stack>
        </DialogActions>
      </StyledDialog>
    </WarningContext.Provider>
  );
}

const useWarning = () => useContext(WarningContext);
export { useWarning, WarningProvider };

const StyledDialog = styled(Dialog)(() => ({
  ".closeButton": {
    position: "absolute",
    right: "10px",
    top: "10px",
    cursor: "pointer",
  },
  ".content": {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));
