import { Modal, Checkbox, Snackbar, SnackbarContent } from "@mui/material";
import { Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Prompt, useHistory, useParams } from "react-router";
import { ErrorPage, NoAccess, TableLoading, useAuth } from "../../sdk";
import { useManualDLCheck, useSeasonDates, useSeasonPricing } from "../hooks";
import ManualDLCheckTable from "./ManualDLCheckTable";
import ModalBody from "./ModelBody";
import SeasonDatesTable from "./SeasonDatesTable";
import SeasonPricingTable from "./SeasonPricingTable";
import {
  CancelButton,
  CardHeader,
  CardPricingForm as Card,
  CopySetting,
  EditButtonIcon,
  HeaderText,
  SaveButton,
  SeasonContainer,
  TextFieldN,
  TitlePricingForm as Title,
  NetworkPrompt,
} from "./Styles";
const PricingSetupToUpdate = {
  LowPricingSetup: false,
  HighPricingSetup: false,
  SeasonSetup: false,
  HotelDLMidweek: false,
  HotelDLWeekend: false,
};

export function PricingForm() {
  const { hotelId } = useParams();
  const { token, authFetch, hotels, permission } = useAuth();
  const history = useHistory();
  const [openSelectbar, setOpenSelectBar] = useState(false);
  const [updateSelectedSetup, setUpdateSelectedPricingSetup] = useState(
    PricingSetupToUpdate
  );
  let isMidweek = true;

  const [selectPricingSetup, setSelectPricingSetup] = useState([]);
  const [copyFromHotelId, setCopyFromHotelId] = useState(null);
  const [copyHotelName, setCopyHotelName] = useState("");
  const [allHotels, setAllHotels] = useState([]);
  const [dlMeedweekValue, setDLMidweek] = useState(0);
  const [dlWeekendValue, setDLWeekend] = useState(0);
  const [isChecked, setIsChecked] = useState(true);

  const handleSelect = (key, value) => {
    if (key === "midweek") {
      if (check) setDaysCount(0);
      setDLMidweek(value);
    
    }else {
      if(check) setWeekendDays(0)
      setDLWeekend(value);
    }
  };
  const handleDLChange = (key, value) => {
    setCheck(value);
    setIsChecked(value);
  };
  let seasonSetup = null,
    acrossHotel = null;
  useEffect(() => {
    if (token) {
      getAllHotels();
    } else {
      return;
    }
  }, [token]);

  for (let key in permission) {
    if (permission.hasOwnProperty(key)) {
      if (permission[key].name === "seasonSetup") {
        seasonSetup = permission[key];
      }
      if (permission[key].name === "acrossHotel") {
        acrossHotel = permission[key];
      }
    }
  }

  async function getAllHotels() {
    if (acrossHotel) {
      const { get } = await authFetch({
        path: `/hotel`,
      });
      const { data, error } = await get();
      if (data) {
        setAllHotels(data);
      } else {
        console.log(error);
      }
    } else {
      setAllHotels(hotels);
    }
  }
  const {
    lsPrices,
    hsPrices,
    isLSInEditMode,
    isHSInEditMode,
    setIsLSInEditMode,
    setIsHSInEditMode,
    isLSCancelled,
    isHSCancelled,
    setIsLSCancelled,
    setIsHSCancelled,
    isLSSaved,
    isHSSaved,
    setIsLSSaved,
    setIsHSSaved,
    onLSPriceChange,
    onHSPriceChange,
    pOpen,
    pHandleCloseStatus,
    pNetworkMsg,
    lonRemoveRowHandler,
    lonAddRowHandler,
    honRemoveRowHandler,
    honAddRowHandler,
    SeasonPricingError,
    lsLoading,
    hsLoading,
    checkLSPricesValidation,
    checkHSPricesValidation,
    errorMsg,
    setErrorMsg,
  } = useSeasonPricing(
    copyFromHotelId,
    updateSelectedSetup.HighPricingSetup,
    updateSelectedSetup.LowPricingSetup,
    openSelectbar
  );

  const {
    seasonDetails,
    isInEditMode,
    setIsInEditMode,
    isCancelled,
    setIsCancelled,
    isSaved,
    setIsSaved,
    onChange,
    dOpen,
    dHandleCloseStatus,
    dNetworkMsg,
    sonRemoveRowHandler,
    sonAddRowHandler,
    SeasonDatesError,
    Loading,
  } = useSeasonDates(
    copyFromHotelId,
    updateSelectedSetup.SeasonSetup,
    openSelectbar
  );

  const {
    details: midweekDetails,
    isDLInEditMode: isMidweekDLInEditMode,
    setIsDLInEditMode: setIsMidweekDLInEditMode,
    isDLCancelled: isMidweekDLCancelled,
    setIsDLCancelled: setIsMidweekDLCancelled,
    isDLSaved: isMidweekDLSaved,
    setIsDLSaved: setIsMidweekDLSaved,
    onDLChange: onMidweekDLChange,
    tableConfig: midweekTableConfig,
    onDLAddRowHandler: onMidweekDLAddRowHandler,
    onDLRemoveRowHandler: onMidweekDLRemoveRowHandler,
    onDLAdvRemoveRowHandle: onMidweekDLAdvRemoveRowHandle,
    onDLAdvAddRowHandler: onMidweekDLAdvAddRowHandler,
    loading: midweekLoading,
    checkMidweekValidation,
    days,
    check,
    weekendDays,
    setWeekendDays,
    setDaysCount,
    setCheck,
  } = useManualDLCheck(
    copyFromHotelId,
    updateSelectedSetup.HotelDLMidweek,
    (isMidweek = true),
    openSelectbar,
    dlMeedweekValue,
    dlWeekendValue,
    isChecked
  );
  const {
    details: weekendDetails,
    isDLInEditMode: isWeekendDLInEditMode,
    setIsDLInEditMode: setIsWeekendDLInEditMode,
    isDLCancelled: isWeekendDLCancelled,
    setIsDLCancelled: setIsWeekendDLCancelled,
    isDLSaved: isWeekendDLSaved,
    setIsDLSaved: setIsWeekendDLSaved,
    onDLChange: onWeekendDLChange,
    tableConfig: weekendTableConfig,
    onDLAddRowHandler: onWeekendDLAddRowHandler,
    onDLRemoveRowHandler: onWeekendDLRemoveRowHandler,
    onDLAdvRemoveRowHandle: onWeekendDLAdvRemoveRowHandle,
    onDLAdvAddRowHandler: onWeekendDLAdvAddRowHandler,
    loading: weekendLoading,
    checkWeekendValidation,
  } = useManualDLCheck(
    copyFromHotelId,
    updateSelectedSetup.HotelDLWeekend,
    (isMidweek = false),
    openSelectbar
  );

  function handlePricingChange(val) {
    setSelectPricingSetup(val);
  }

  function updatePricingSetup() {
    setOpenSelectBar(false);

    setUpdateSelectedPricingSetup({
      LowPricingSetup: selectPricingSetup.some(
        (val) => val === "LowPricingSetup"
      ),
      HighPricingSetup: selectPricingSetup.some(
        (val) => val === "HighPricingSetup"
      ),
      SeasonSetup: selectPricingSetup.some((val) => val === "SeasonSetup"),
      HotelDLMidweek: selectPricingSetup.some(
        (val) => val === "HotelDLMidweek"
      ),
      HotelDLWeekend: selectPricingSetup.some(
        (val) => val === "HotelDLWeekend"
      ),
    });
  }
  const [shouldBlockNavigation, setShouldBlockNavigation] = useState(false);

  useEffect(() => {
    setShouldBlockNavigation(
      isInEditMode ||
        isLSInEditMode ||
        isHSInEditMode ||
        isMidweekDLInEditMode ||
        isWeekendDLInEditMode
    );
  }, [
    isInEditMode,
    isLSInEditMode,
    isHSInEditMode,
    isMidweekDLInEditMode,
    isWeekendDLInEditMode,
  ]);

  return !SeasonPricingError && !SeasonDatesError && seasonSetup ? (
    <>
      <CopySetting>
        <HeaderText>Hotel Season Setup</HeaderText>
        <Button
          variant="contained"
          size="small"
          onClick={() => {
            setUpdateSelectedPricingSetup(PricingSetupToUpdate);
            setOpenSelectBar(true);
          }}
        >
          Copy settings
        </Button>
      </CopySetting>
      <div>
        <Prompt
          when={shouldBlockNavigation}
          message="You have Unsave Changes, Are you sure want to leave?"
        />
        <Modal open={openSelectbar} onClose={() => setOpenSelectBar(false)}>
          <div>
            <ModalBody
              copyFromHotelId={copyFromHotelId}
              setCopyFromHotelId={setCopyFromHotelId}
              copyHotelName={copyHotelName}
              setCopyHotelName={setCopyHotelName}
              handlePricingChange={handlePricingChange}
              selectPricingSetup={selectPricingSetup}
              updatePricingSetup={updatePricingSetup}
              setOpenSelectBar={setOpenSelectBar}
              allHotels={allHotels}
              hotelId={hotelId}
            />
          </div>
        </Modal>

        <div style={{ overflow: "scroll", height: "calc(100vh - 200px)" }}>
          <SeasonContainer>
            <Card>
              <CardHeader>
                <Title>High Season</Title>
                {!isHSInEditMode ? (
                  <EditButtonIcon
                    onClick={() => {
                      setIsHSInEditMode(!isHSInEditMode);
                    }}
                  />
                ) : (
                  <div>
                    <CancelButton
                      onClick={() => {
                        setIsHSCancelled(!isHSCancelled);
                        setIsHSInEditMode(!isHSInEditMode);
                      }}
                    >
                      Cancel
                    </CancelButton>
                    <SaveButton
                      onClick={() => {
                        setIsHSSaved(!isHSSaved);
                        setIsHSInEditMode(!isHSInEditMode);
                      }}
                      style={{
                        backgroundColor: "#306fbc",
                        color: "white",
                        fontWeight: "bold",
                        fontFamily: "Roboto",
                        marginLeft: "8px",
                      }}
                      disabled={checkHSPricesValidation}
                    >
                      Save
                    </SaveButton>
                  </div>
                )}
              </CardHeader>
              <div>
                {!hsLoading ? (
                  <SeasonPricingTable
                    isInEditMode={isHSInEditMode}
                    setIsInEditMode={setIsHSInEditMode}
                    isCancelled={isHSCancelled}
                    onChange={onHSPriceChange}
                    prices={hsPrices}
                    open={pOpen}
                    handleCloseStatus={pHandleCloseStatus}
                    networkMsg={pNetworkMsg}
                    onRemoveRowHandler={honRemoveRowHandler}
                    onAddRowHandler={honAddRowHandler}
                  />
                ) : (
                  <TableLoading />
                )}
              </div>
            </Card>
            <Card>
              <CardHeader>
                <Title>Low Season</Title>
                {!isLSInEditMode ? (
                  <EditButtonIcon
                    onClick={() => {
                      setIsLSInEditMode(!isLSInEditMode);
                    }}
                  />
                ) : (
                  <div>
                    <CancelButton
                      onClick={() => {
                        setIsLSCancelled(!isLSCancelled);
                        setIsLSInEditMode(!isLSInEditMode);
                      }}
                    >
                      Cancel
                    </CancelButton>
                    <SaveButton
                      onClick={() => {
                        setIsLSSaved(!isLSSaved);
                        setIsLSInEditMode(!isLSInEditMode);
                      }}
                      style={{
                        backgroundColor: "#306fbc",
                        color: "white",
                        fontWeight: "bold",
                        fontFamily: "Roboto",
                        marginLeft: "8px",
                      }}
                      disabled={checkLSPricesValidation}
                    >
                      Save
                    </SaveButton>
                  </div>
                )}
              </CardHeader>
              {!lsLoading ? (
                <SeasonPricingTable
                  isInEditMode={isLSInEditMode}
                  onChange={onLSPriceChange}
                  prices={lsPrices}
                  open={pOpen}
                  handleCloseStatus={pHandleCloseStatus}
                  networkMsg={pNetworkMsg}
                  onRemoveRowHandler={lonRemoveRowHandler}
                  onAddRowHandler={lonAddRowHandler}
                />
              ) : (
                <TableLoading />
              )}
            </Card>

            <Card>
              <CardHeader>
                <Title>Season Dates</Title>
                {!isInEditMode ? (
                  <EditButtonIcon
                    onClick={() => {
                      setIsInEditMode(!isInEditMode);
                    }}
                  />
                ) : (
                  <div>
                    <CancelButton
                      onClick={() => {
                        setIsCancelled(!isCancelled);
                        setIsInEditMode(!isInEditMode);
                      }}
                    >
                      Cancel
                    </CancelButton>
                    <SaveButton
                      onClick={() => {
                        setIsSaved(!isSaved);
                        setIsInEditMode(!isInEditMode);
                      }}
                      style={{
                        backgroundColor: "#306fbc",
                        color: "white",
                        fontWeight: "bold",
                        fontFamily: "Roboto",
                        marginLeft: "8px",
                      }}
                    >
                      Save
                    </SaveButton>
                  </div>
                )}
              </CardHeader>

              {!Loading ? (
                <SeasonDatesTable
                  seasonDetails={seasonDetails}
                  isInEditMode={isInEditMode}
                  onChange={onChange}
                  open={dOpen}
                  handleCloseStatus={dHandleCloseStatus}
                  networkMsg={dNetworkMsg}
                  onRemoveRowHandler={sonRemoveRowHandler}
                  onAddRowHandler={sonAddRowHandler}
                />
              ) : (
                <TableLoading />
              )}
            </Card>
          </SeasonContainer>

          <SeasonContainer style={{ justifyContent: "flex-start" }}>
            <Card style={{ width: "49%" }}>
              <CardHeader>
                <Title>Hotel DL Check Weekday</Title>
                <Checkbox
                  checked={check}
                  onChange={(e) =>
                    handleDLChange(
                      "isDLCheckMidweek",
                      e.target.checked
                    )
                  }
                  style={{ color: "#306fbc", width: "30px", height: "30px" }}
                />

                {check && (
                  <TextFieldN
                    type="number"
                    disabled={!isMidweekDLInEditMode}
                    InputProps={{
                      inputProps: { min: 0, max: 45 },
                      disableUnderline: true,
                    }}
                    value={days > 0 ? days : dlMeedweekValue}
                    onChange={(e) =>
                      handleSelect("midweek", e.target.value)
                    }
                  />
                )}
                {!isMidweekDLInEditMode ? (
                  <EditButtonIcon
                    sx={{cursor: "pointer"}}
                    onClick={() => {
                      const val = days > 0 ? days : dlMeedweekValue;
                      const err = midweekDetails.some(
                        (item) => item.advanceMin > val || item.advanceMax > val
                      );

                      setIsMidweekDLInEditMode(!isMidweekDLInEditMode);
                      if (err) {
                        setErrorMsg(true);
                      }
                    }}
                  />
                ) : (
                  <div>
                    <CancelButton
                      onClick={() => {
                        setIsMidweekDLCancelled(!isMidweekDLCancelled);
                        setIsMidweekDLInEditMode(!isMidweekDLInEditMode);
                      }}
                    >
                      Cancel
                    </CancelButton>
                    <SaveButton
                      onClick={() => {
                        const val = days > 0 ? days : dlMeedweekValue;
                        const err = midweekDetails.some(
                          (item) =>
                            item.advanceMin > val || item.advanceMax > val
                        );
                        if (err) {
                          setErrorMsg(true);
                          return;
                        }
                        setIsMidweekDLSaved(!isMidweekDLSaved);
                        setIsMidweekDLInEditMode(!isMidweekDLInEditMode);
                      }}
                      style={{
                        backgroundColor: "#306fbc",
                        color: "white",
                        fontWeight: "bold",
                        fontFamily: "Roboto",
                        marginLeft: "8px",
                      }}
                      disabled={checkMidweekValidation}
                    >
                      Save
                    </SaveButton>
                  </div>
                )}
              </CardHeader>
              {!midweekLoading ? (
                <ManualDLCheckTable
                  tableConfig={midweekTableConfig}
                  details={midweekDetails}
                  isInEditMode={isMidweekDLInEditMode}
                  onChange={onMidweekDLChange}
                  onDLAddRowHandler={onMidweekDLAddRowHandler}
                  onDLRemoveRowHandler={onMidweekDLRemoveRowHandler}
                  onDLAdvRemoveRowHandle={onMidweekDLAdvRemoveRowHandle}
                  onDLAdvAddRowHandler={onMidweekDLAdvAddRowHandler}
                  dlMeedweekValue={days > 0 ? days : dlMeedweekValue}
                />
              ) : (
                <TableLoading />
              )}
            </Card>
            {/* <Card /> */}
            <Card style={{ width: "49%", marginLeft: "30px" }}>
              <CardHeader>
                <Title>Hotel DL Check Weekend</Title>
                <Checkbox
                  checked={check}
                  onChange={(e) =>
                    handleDLChange(
                      "isDLCheckWeekend",
                      e.target.checked
                    )
                  }
                  style={{ color: "#306fbc", width: "30px", height: "30px" }}
                />
                {check && (
                  <TextFieldN
                    type="number"
                    disabled={!isWeekendDLInEditMode}
                    InputProps={{
                      inputProps: { min: 0, max: 45 },
                      disableUnderline: true,
                    }}
                    value={weekendDays > 0 ? weekendDays : dlWeekendValue}
                    onChange={(e) =>
                      handleSelect("weekend", e.target.value)
                    }
                  />
                )}
                {!isWeekendDLInEditMode ? (
                  <EditButtonIcon
                    sx={{cursor: "pointer"}}
                    onClick={() => {
                      const val = weekendDays > 0 ? weekendDays : dlWeekendValue;
                      const err = weekendDetails.some(
                        (item) => item.advanceMin > val || item.advanceMax > val
                      );
                      setIsWeekendDLInEditMode(!isWeekendDLInEditMode);
                      if (err) {
                        setErrorMsg(true);
                      }
                    }}
                  />
                ) : (
                  <div>
                    <CancelButton
                      onClick={() => {
                        setIsWeekendDLCancelled(!isWeekendDLCancelled);
                        setIsWeekendDLInEditMode(!isWeekendDLInEditMode);
                      }}
                    >
                      Cancel
                    </CancelButton>
                    <SaveButton
                      onClick={() => {
                        const val = weekendDays > 0 ? weekendDays : dlWeekendValue;
                        const err = weekendDetails.some(
                          (item) =>
                            item.advanceMin > val || item.advanceMax > val
                        );
                        if (err) {
                          setErrorMsg(true);
                          return;
                        }
                        setIsWeekendDLSaved(!isWeekendDLSaved);
                        setIsWeekendDLInEditMode(!isWeekendDLInEditMode);
                      }}
                      style={{
                        backgroundColor: "#306fbc",
                        color: "white",
                        fontWeight: "bold",
                        fontFamily: "Roboto",
                        marginLeft: "8px",
                      }}
                      disabled={checkWeekendValidation}
                    >
                      Save
                    </SaveButton>
                  </div>
                )}
              </CardHeader>
              {!weekendLoading ? (
                <ManualDLCheckTable
                  tableConfig={weekendTableConfig}
                  details={weekendDetails}
                  isInEditMode={isWeekendDLInEditMode}
                  onChange={onWeekendDLChange}
                  onDLAddRowHandler={onWeekendDLAddRowHandler}
                  onDLRemoveRowHandler={onWeekendDLRemoveRowHandler}
                  onDLAdvRemoveRowHandle={onWeekendDLAdvRemoveRowHandle}
                  onDLAdvAddRowHandler={onWeekendDLAdvAddRowHandler}
                  dlMeedweekValue={weekendDays > 0 ? weekendDays : dlWeekendValue}
                />
              ) : (
                <TableLoading />
              )}
            </Card>
            {/* <Card /> */}
          </SeasonContainer>
        </div>
      </div>
      <NetworkPrompt>
        <Snackbar
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          open={errorMsg}
          autoHideDuration={4000}
          onClose={() => setErrorMsg(false)}
        >
          <SnackbarContent
            style={{ backgroundColor: "#CA3433" }}
            message={`You cannot insert more than ${
              days > 0 ? days : dlMeedweekValue
            } days in advance`}
          />
        </Snackbar>
      </NetworkPrompt>
    </>
  ) : !seasonSetup ? (
    <NoAccess />
  ) : (
    <ErrorPage />
  );
}
